import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface Props {}

const SetupSupplier = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Setup Supplier"
    }, []);

    const [alert, setAlert] = useState(true);

    const [showSupplier, setShowSupplier] = useState(false);
    const [editSupplier, setShowEditSupplier] = useState(false);
    const [removeSupplier, setShowRemoveSupplier] = useState(false);
    
    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline"> 
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Setup Supplier</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="content">
                {alert &&
                <div className="alert alert-info alert-styled-left alert-arrow-left alert-dismissible bg-white mb-4" >
                    <button type="button" className="close" onClick={() => setAlert(false)}><span>×</span></button>
                    <h6 className="alert-heading font-weight-semibold mb-1">Setup Wholesale Supplier</h6>
                    Click add supplier button and select your desired wholesale supplier.
                    <h6 className="alert-heading font-weight-semibold mb-1">
                        <span className="badge badge-light badge-striped badge-striped-left border-left-warning">Note: As of now Amazon is the only supported Platform/Marketplace.</span>
                    </h6>
                </div>
                }

                <div className="card br-10">
                    <div className="card-header header-elements-inline">
                        <h5 className="card-title">Listed Suppliers</h5>
                        <div className="header-elements">
                            <button type="button" className="btn btn-primary" onClick={() => setShowSupplier(true)}><i className="icon-folder-plus2 mr-2"></i> ADD NEW SUPPLIER</button>
                        </div>
                    </div>

                    <ul className="nav nav-tabs nav-tabs-highlight mb-2">
                        <li className="nav-item"><a href="#automated" className="nav-link rounded-top active automated" data-toggle="tab">Automated Suppliers</a></li>
                    </ul>

                    <div className="row p-2">
                        <div className="col-xl-12">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="automated">
                                    <div className="col-xl-12">
                                        <div style={{overflowY: 'auto',minHeight: '455px',maxHeight: '610px'}}>
                                            <table className="table table-xs listing-table table-hover text-center">
                                                <thead>
                                                    <tr>
                                                        <th>LOGO</th>
                                                        <th className='text-left'>SUPPLIER</th>
                                                        <th className='text-nowrap'>QUEUE/CATALOG ITEMS</th>
                                                        <th className='text-nowrap'>HANDLING TIME</th>
                                                        <th className='text-nowrap'>RESTOCK QUANTITY</th>
                                                        <th className='text-nowrap'>TAX RATE %</th>
                                                        <th className='text-center'>ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src="./management/public/uploads/supplier/AEWholesale.png" alt="" height="80" />
                                                        </td>
                                                        <td className='text-left'><a href="https://www.aewholesale.com" target="_blank">AEWholesale</a></td>
                                                        <td>15</td>
                                                        <td><span>5</span></td>
                                                        <td><span>5</span></td>
                                                        <td><span>3</span></td>
                                                        <td className='text-center'>
                                                            <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                <Dropdown.Item href="#" onClick={() => setShowEditSupplier(true)}><i className="icon-pencil3 text-info"></i> EDIT</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => setShowRemoveSupplier(true)}><i className="icon-cross2 text-danger"></i> REMOVE</Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="./management/public/uploads/supplier/bangalla.jpg" alt="" height="80" />
                                                        </td>
                                                        <td className='text-left'><a href="https://www.bangalla.com" target="_blank">Bangalla</a></td>
                                                        <td>0</td>
                                                        <td><span>5</span></td>
                                                        <td><span>5</span></td>
                                                        <td><span>4</span></td>
                                                        <td className='text-center'>
                                                            <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                <Dropdown.Item href="#" onClick={() => setShowEditSupplier(true)}><i className="icon-pencil3 text-info"></i> EDIT</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => setShowRemoveSupplier(true)}><i className="icon-cross2 text-danger"></i> REMOVE</Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="./management/public/uploads/supplier/ckbproducts.jpg" alt="" height="80" />
                                                        </td>
                                                        <td className='text-left'><a href="https://www.ckbproducts.com" target="_blank">CKB Products</a></td>
                                                        <td>1</td>
                                                        <td><span>7</span></td>
                                                        <td><span>7</span></td>
                                                        <td><span>3</span></td>
                                                        <td className='text-center'>
                                                            <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                <Dropdown.Item href="#" onClick={() => setShowEditSupplier(true)}><i className="icon-pencil3 text-info"></i> EDIT</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => setShowRemoveSupplier(true)}><i className="icon-cross2 text-danger"></i> REMOVE</Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="./management/public/uploads/supplier/ctwhomecollection.png" alt="" height="80" />
                                                        </td>
                                                        <td className='text-left'><a href="https://ctwhomecollection.com/" target="_blank">CTW Home Collection</a></td>
                                                        <td>5</td>
                                                        <td><span>5</span></td>
                                                        <td><span>10</span></td>
                                                        <td><span>9</span></td>
                                                        <td className='text-center'>
                                                            <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                <Dropdown.Item href="#" onClick={() => setShowEditSupplier(true)}><i className="icon-pencil3 text-info"></i> EDIT</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => setShowRemoveSupplier(true)}><i className="icon-cross2 text-danger"></i> REMOVE</Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={showSupplier} onHide={() => setShowSupplier(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        SELECT YOUR SUPPLIER
                        <button type="button" className='close' onClick={() => setShowSupplier(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <div className="input-group mb-3" style={{width: '25vw'}}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">Search :</span>
                            </div>
                            <input type="text" id="setupSupplierTableSearch" className="form-control" placeholder="Search.." />
                        </div>
                    </center>
                    <table className="table tasks-list table-lg">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>LOGO</th>
                                <th>SUPPLIER</th>
                                <th>NUMBER OF ITEMS</th>
                            </tr>
                        </thead>
                        <tbody id="setupSupplierTable">
                            <tr>
                                <td className="text-nowrap text-center">
                                    <span><i className="icon-checkmark4" style={{color: 'green'}}></i></span>
                                </td>
                                <td><img src="./management/public/uploads/supplier/AEWholesale.png" alt="" height="30" /></td>
                                <td>AEWholesale</td>
                                <td>1,125</td>
                            </tr>
                            <tr>
                                <td className="text-nowrap text-center">
                                    <span><i className="icon-checkmark4" style={{color: 'green'}}></i></span>
                                </td>
                                <td><img src="./management/public/uploads/supplier/ckbproducts.jpg" alt="" height="30" /></td>
                                <td>CKB Products</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td className="text-nowrap text-center">
                                    <span><i className="icon-checkmark4" style={{color: 'green'}}></i></span>
                                </td>
                                <td><img src="./management/public/uploads/supplier/ctwhomecollection.png" alt="" height="30" /></td>
                                <td>CTW Home Collection</td>
                                <td>2,739</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>

            <Modal show={editSupplier} onHide={() => setShowEditSupplier(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        EDIT SUPPLIER
                        <button type="button" className='close' onClick={() => setShowEditSupplier(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>SUPPLIER:</label><br />
                                <span id="modalsupplier">AEWholesale</span>
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>Images:</label><br />
                                <img id="settings_image" alt="" width="60" height="30" src="./assets/img/supplier/AEWholesale.png" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>HANDLING TIME</label>
                                <input id="handling_time" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>RESTOCK QUANTITY</label>
                                <input id="res_qty" className="form-control" />
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>TAX RATE %</label>
                                <input id="res_tax" className="form-control" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowEditSupplier(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={removeSupplier} onHide={() => setShowRemoveSupplier(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to remove this supplier?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setShowRemoveSupplier(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SetupSupplier