import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClockRotateLeft, faCheck, faBan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const Tickets = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | My Tickets"
    }, []);

    const [addTicket, setAddTicket] = useState(false);
    const [fullHistory, setFullHistory] = useState(false);
    const [resolvedTicket, setResolvedTicket] = useState(false);
    const [closedTicket, setClosedTicket] = useState(false);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb"> 
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Support</a>
                            <a href="#" className="breadcrumb-item">My Tickets</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row mb-2">
                    <div className="col text-right">
                        <a href="#" className="btn bg-success-400 rounded-round" onClick={() => setAddTicket(true)}>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faPlus} className='fa-2x' />
                                <h4 className="m-0 pl-1">Add Ticket</h4>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-4">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-ticket icon-3x text-primary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">5</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Active Tickets</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-checkmark4 icon-3x text-success"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">1</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Resolved Tickets</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-blocked icon-3x text-secondary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">2</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Closed Tickets</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card br-10">
                    <Tabs
                        defaultActiveKey="active"
                        id="uncontrolled-tab-example"
                        className="mb-2"
                        style={{fontSize: '18px'}}
                        >
                        <Tab eventKey="active" title="Active Tickets">
                            <div className="table-responsive" style={{minHeight: '500px'}}>
                                <table className="table text-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{width: '50px'}}>ACTION</th>
                                            <th style={{width: '10px'}}>DATE</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setResolvedTicket(true)}><FontAwesomeIcon icon={faCheck} className='mr-2 text-success' /> Resolve Issue</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setClosedTicket(true)}><FontAwesomeIcon icon={faBan} className='mr-2' /> Close Issue</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="12" data-toggle="modal" data-target="#historyModal">09/25/2023 05:53AM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="12" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230925055331]</span> Test Ticket No <span className="badge bg-danger badge-pill">6</span></h6>
                                                    <span className="text-muted">Check ticketno format</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setResolvedTicket(true)}><FontAwesomeIcon icon={faCheck} className='mr-2 text-success' /> Resolve Issue</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setClosedTicket(true)}><FontAwesomeIcon icon={faBan} className='mr-2' /> Close Issue</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="8" data-toggle="modal" data-target="#historyModal">09/22/2023 08:14AM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="8" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230922081457]</span> Downgrade Plan <span className="badge bg-danger badge-pill">3</span></h6>
                                                    <span className="text-muted">Dev Test</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <div className="list-icons">
                                                    <div className="list-icons-item dropdown">
                                                        <a href="#" className="list-icons-item dropdown-toggle caret-0" data-toggle="dropdown"><i className="icon-menu7"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a href="#" className="dropdown-item ticketHistory" data-id="5" data-toggle="modal" data-target="#historyModal"><i className="icon-history"></i> Full History</a>
                                                            <div className="dropdown-divider"></div>
                                                            <a href="#" className="dropdown-item resolveissue" data-id="5"><i className="icon-checkmark3 text-success"></i> Resolve Issue</a>
                                                            <a href="#" className="dropdown-item closeissue" data-id="5"><i className="icon-cross2 text-danger"></i> Close Issue</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="5" data-toggle="modal" data-target="#historyModal">08/07/2023 03:29PM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="5" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230807152953]</span> test <span className="badge bg-danger badge-pill">5</span></h6>
                                                    <span className="text-muted">test</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setResolvedTicket(true)}><FontAwesomeIcon icon={faCheck} className='mr-2 text-success' /> Resolve Issue</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setClosedTicket(true)}><FontAwesomeIcon icon={faBan} className='mr-2' /> Close Issue</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="4" data-toggle="modal" data-target="#historyModal">08/06/2023 12:24AM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="4" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230806002439]</span> This is test <span className="badge bg-danger badge-pill">15</span></h6>
                                                    <span className="text-muted">Hello World</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setResolvedTicket(true)}><FontAwesomeIcon icon={faCheck} className='mr-2 text-success' /> Resolve Issue</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setClosedTicket(true)}><FontAwesomeIcon icon={faBan} className='mr-2' /> Close Issue</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="2" data-toggle="modal" data-target="#historyModal">08/05/2023 08:24PM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="2" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230805202406]</span> Test <span className="badge bg-danger badge-pill">1</span></h6>
                                                    <span className="text-muted">Test</span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab eventKey="resolved" title="Resolved Tickets">
                            <div className="table-responsive" style={{minHeight: '500px'}}>
                                <table className="table text-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{width: '50px'}}>ACTION</th>
                                            <th style={{width: '10px'}}>DATE</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => setResolvedTicket(true)}><FontAwesomeIcon icon={faTriangleExclamation} className='mr-2 text-danger' /> Unresolve Issue</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="6" data-toggle="modal" data-target="#historyModal">08/14/2023 01:42PM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="6" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230814134257]</span> test1 <span className="badge bg-danger badge-pill">6</span></h6>
                                                    <span className="text-muted">test1</span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab eventKey="closed" title="Closed Tickets">
                            <div className="table-responsive" style={{minHeight: '500px'}}>
                                <table className="table text-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{width: '50px'}}>ACTION</th>
                                            <th style={{width: '10px'}}>DATE</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="13" data-toggle="modal" data-target="#historyModal">09/25/2023 06:54AM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="13" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230925094522]</span> Test <span className="badge bg-danger badge-pill">0</span></h6>
                                                    <span className="text-muted">Test</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                    <Dropdown.Item href="#" onClick={() => setFullHistory(true)}><FontAwesomeIcon icon={faClockRotateLeft} className='mr-2' /> Full History</Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            <td className="text-center">
                                                <div className="font-weight-semibold ticketHistory cursor-pointer" data-id="3" data-toggle="modal" data-target="#historyModal">08/06/2023 12:21AM</div>
                                            </td>
                                            <td>
                                                <a href="#" className="text-default ticketHistory" data-id="3" data-toggle="modal" data-target="#historyModal">
                                                    <h6 className="font-weight-semibold m-0"><span className="text-primary">[#230806002111]</span> <span className="badge bg-danger badge-pill">0</span></h6>
                                                    <span className="text-muted">Sample Ticket</span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <Modal show={addTicket} onHide={() => setAddTicket(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Add Ticket
                        <button type="button" className='close' onClick={() => setAddTicket(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Priority Level:</label><br />
                                <select id="supportlevelticket" className="form-control">
                                    <option value="">-- Select Priority -- </option>
                                    <option value="1">Critical</option>
                                    <option value="2">Important</option>
                                    <option value="3">Normal</option>
                                    <option value="4">Low</option>
                                    <option value="5">Suggestion</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Subject:</label><br />
                                <input type="text" id="ticketsubject" className="form-control" />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea id="descriptionticket" className="form-control" rows={5}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setAddTicket(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>

            <Modal size='xl' show={fullHistory} onHide={() => setFullHistory(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Add Ticket
                        <button type="button" className='close' onClick={() => setFullHistory(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <h6>
                                Ticket Details <span className="text-primary">[#230925055331]</span> -
                                <span className="badge bg-primary">Active</span>
                                <span className="d-block">09/25/2023 05:53AM</span>
                            </h6>
                            <div className="card">
                                <div className="card-body" style={{background: '#0c5483', color: '#fff'}}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="text-nowrap">Priority Level:</label>
                                                <input id="supportlevel" className="form-control" value="Critical" />
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Subject:</label><br />
                                                <input id="subject" className="form-control" value="Test Ticket No" />
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group mb-0">
                                                <label>Description</label>
                                                <textarea id="description" rows={5} className="form-control">Check ticketno format</textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel panel-default">
                                        <div className="text-right">
                                            <a href="#" type="button" className="btn btn-primary uploadticketfile" data-toggle="modal" data-target="#myModalattachedfile" data-id="12">
                                                <i className="glyphicon glyphicon-floppy-disk" aria-hidden="true"></i> Attached Files <span className="badge" style={{background: '#4caf50'}}>0</span>
                                            </a>
                                        </div>
                                        <div className="panel-body mt-3" style={{overflow: 'auto', height: 'calc(100vh - 190px)'}}>
                                            <table className="table table-striped table-bordered table-condensed" style={{fontSize: '90%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <h6>Test reply1</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block">WHx Administrator</span>
                                                                <b>09/26/2023 06:09AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6>Response1</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block"></span>
                                                                <b>09/26/2023 06:11AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6>Test reply2</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block">WHx Administrator</span>
                                                                <b>09/26/2023 06:12AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6>Response2</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block"></span>
                                                                <b>09/26/2023 06:23AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6>Test reply3</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block">WHx Administrator</span>
                                                                <b>09/26/2023 06:24AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <h6>Test reply4</h6>
                                                            <p className="m-0">
                                                                <span className="text-primary d-block">WHx Administrator</span>
                                                                <b>09/26/2023 06:30AM</b>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <center>
                                                                        <p>
                                                                            <b>Message Here <i className="glyphicon glyphicon-arrow-down"></i></b>
                                                                        </p>
                                                                    </center>
                                                                    <textarea name="message" className="form-control" rows={3} id="message"></textarea>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-md-12 text-center">
                                                                    <button className="btn btn-primary" id="historySendMessage" data-id="12">Submit</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={resolvedTicket} onHide={() => setResolvedTicket(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to Resolve the Ticket?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setResolvedTicket(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={closedTicket} onHide={() => setClosedTicket(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to Close the Ticket?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setClosedTicket(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Tickets