import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import './../../components/assets/global_assets/css/icons/icomoon/styles.min.css';
import './../../components/assets/css/bootstrap.min.css';
import './../../components/assets/css/bootstrap_limitless.min.css';
import './../../components/assets/css/layout.min.css';
import './../../components/assets/css/components.min.css';
import './../../components/assets/css/colors.min.css';
import './../../App.css';

const Theme = () => {
    const navigate = useNavigate();

    useEffect(() => {
        var userData = localStorage.getItem('userData');
        console.log(userData);
        
        if(userData){
            navigate('/dashboard');
        }
    }, []);

    return (
        <div className="page-content"> 
            <div className="content-wrapper">
                <Outlet context='https://reactjs.wholesalex.app/api' />
            </div>
        </div>
    )
}

export default Theme