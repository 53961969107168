import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {}

const Sitemap = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Sitemap";
    }, []);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline"> 
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Sitemap</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="content">
                <div className="text-center mb-3 py-2">
                    <h4 className="font-weight-semibold mb-1">Sitemap</h4>
                    <span className="text-muted d-block">List of pages that you might be looking for.</span>
                </div>

                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="card br-10">
                            <div className="card-body">
                                <h5 className="mb-1"><i className="icon-home4 mr-1"></i> Dashboard</h5>
                                <ul>
                                    <li><a href="index.php">Sales Report</a></li>
                                    <li><a href="index.php">Profit Report</a></li>
                                    <li><a href="index.php">Sales Forecast</a></li>
                                    <li><a href="index.php">Top Selling Items</a></li>
                                    <li><a href="index.php">Inventory</a></li>
                                    <li><a href="index.php">Suppliers</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-repo-forked mr-1"></i> Setup Supplier</h5>
                                <ul>
                                    <li><a href="index.php?page=setup">Listed Suppliers</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-copy mr-1"></i> Inventory</h5>
                                <ul>
                                    <li><a href="index.php?page=feed/inventory">Feed</a></li>
                                    <li><a href="index.php?page=queue/inventory">Queue</a></li>
                                    <li><a href="index.php?page=catalog/inventory">Catalog</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-file-text2 mr-1"></i> File Transfer</h5>
                                <ul>
                                    <li><a href="index.php?page=bulk/wholesale">Feed - Item Bulk Upload</a></li>
                                    <li><a href="index.php?page=bulk/wholesale">Queue - Item Bulk Upload</a></li>
                                    <li><a href="index.php?page=bulk/wholesale">Catalog - Item Bulk Upload</a></li>
                                    <li><a href="index.php?page=bulk/wholesale">Catalog - Delete Close Listing Items</a></li>
                                    <li><a href="index.php?page=bulk/wholesale">Sample CSV Item Upload</a></li>
                                    <li><a href="index.php?page=bulk/wholesale">Source Code Lists</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-gift mr-1"></i> Order Management</h5>
                                <ul>
                                    <li><a href="index.php?page=order">Order Lists</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-link2 mr-1"></i> Integration</h5>
                                <ul>
                                    <li><a href="index.php?page=integration">Amazon Selling Partner API</a></li>
                                    <li><a href="index.php?page=integration">PricePointAI</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="icon-lifebuoy mr-1"></i> Support</h5>
                                <ul>
                                    <li><a href="index.php?page=tickets/support">My Tickets</a></li>
                                    <li><a href="index.php?page=faq/support">FAQ</a></li>
                                    <li><a href="index.php?page=knowedgebase/support">Knowledgebase</a></li>
                                </ul>

                                <h5 className="mb-1"><i className="fa fa-comments mr-1"></i> Forum</h5>
                                <ul>
                                    <li><a href="index.php?page=forum">My Forum</a></li>
                                    <li><a href="index.php?page=forum">Global</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sitemap