import {useState} from 'react'
import { NavLink } from 'react-router-dom';

function OrderDetails() {
    const [profit, setProfit] = useState(-1);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <NavLink to="/order-management" className="breadcrumb-item">Order Management</NavLink>
                            <a href="#" className="breadcrumb-item">Order Details</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="tableFixHead">
                                    <table className="table table-xs listing-table">
                                        <thead>
                                            <tr>
                                                <th>PRODUCT NAME</th>
                                                <th>SKU</th>
                                                <th>QTY</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>CoscoProducts Deluxe 8 foot x 30 inch Fold-in-Half Blow Molded Folding Table, White</td>
                                                <td>JR1445001</td>
                                                <td>1</td>
                                                <td>$143.43</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td>$143.43</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Buyer's Info</h5>
                            </div>
                            <div className="card-body">
                                <p><b>Buyer Name:</b> melanie foret</p>
                                <p><b>Buyer Phone:</b> +1 480-618-5344 ext. 10974</p>
                                <p><b>Buyer Email:</b> sncmhjz14t9lxx5@marketplace.amazon.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Recipient's Info</h5>
                            </div>
                            <div className="card-body">
                                <p><b>Recepient Name:</b> melanie foret</p>
                                <p><b>Recepient Phone:</b> +1 480-618-5344 ext. 10974</p>
                                <p><b>Recepient Address:</b> 445 AVENUE C, WESTWEGO, LA, US 70094-3612</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-8">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Order Details</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Supplier Cost</label>
                                            <input type="text" className="form-control" value="120" id="supplierCost" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Supplier Order ID</label>
                                            <input type="text" className="form-control" value="99934312" id="supplierOrderId" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>4 Digits CC</label>
                                            <input type="text" className="form-control" value="1234" id="digitcc" />
                                        </div>
                                    </div>
                                </div>
                                <div id="tracking-items" style={{padding: '0 10px'}}>
                                    <div className="row position-relative tracking-item mb-3 bg-light">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Shipment Tracking #</label>
                                                <input type="hidden" name="id[]" value="99" className="trackingid" />
                                                <input type="text" className="form-control tracking" name="tracking[]" value="12345678" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Shipping Method</label>
                                                <select className="form-control shipmethod" name="shipmethod[]">
                                                    <option value="3">FedEx</option>
                                                    <option value="2">UPS</option>
                                                    <option value="1">USPS</option>
                                                    <option value="18">4PX</option>
                                                    <option value="9">OnTrac</option>
                                                    <option value="63">China Post</option>
                                                    <option value="71">Estes</option>
                                                    <option value="70">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group input-daterange">
                                                <label>Shipping Date</label>
                                                <input type="date" className="form-control shipdate" name="shipdate[]" value="2023-08-29" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 shipmethod-services">
                                            <div className="form-group">
                                                <label>Shipping Services</label>
                                                <input type="hidden" className="shipservice" value="5" />
                                                <select className="form-control shipmethodservice" name="shipmethodservice[]">
                                                    <option value="0">Standard</option>
                                                    <option value="1">UPS 2nd Day Air</option>
                                                    <option value="2">UPS 3 Day Select</option>
                                                    <option value="3">UPS Ground</option>
                                                    <option value="4">UPS i-parcel International Select</option>
                                                    <option value="5">UPS Next Day Air</option>
                                                    <option value="6">UPS Next Day Air Saver</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" id="addTracking" className="btn btn-primary btn-sm"><i className="fa fa-plus"></i> Add Tracking</button>

                                <div className="card mt-3 refundReason-wrap">
                                    <div className="card-header bg-light">
                                        <h5 className="card-title return-title">RETURN-REFUNDED</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Refund Amount</label>
                                                <input type="number" id="refundAmount" value="143.43" className="form-control" />
                                            </div>
                                            <div className="col-md-8">
                                                <label>Reason of Return</label>
                                                <input type="hidden" id="refundReason-val" value="14" />
                                                <select className="form-control" id="refundReason">
                                                    <option value="">Please select</option>
                                                    <option value="1">No Inventory</option>
                                                    <option value="2">Customer Return</option>
                                                    <option value="3">General Adjustment</option>
                                                    <option value="4">Could Not Ship</option>
                                                    <option value="5">Different Item</option>
                                                    <option value="6">Abandoned</option>
                                                    <option value="7">Customer Cancel</option>
                                                    <option value="8">Price Error</option>
                                                    <option value="9">Product Out of Stock</option>
                                                    <option value="10">Customer Address Incorrect</option>
                                                    <option value="11">Exchange</option>
                                                    <option value="12">Other</option>
                                                    <option value="13">Carrier Credit Decision</option>
                                                    <option value="14">Risk Assessment Information Not Valid</option>
                                                    <option value="15">Carrier Coverage Failure</option>
                                                    <option value="16">Transaction Record</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-3 returnReplacement-wrap">
                                    <div className="card-header bg-light">
                                        <h5 className="card-title">RETURN SHIPMENT TRACKING</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Shipment Tracking #</label>
                                                    <input type="hidden" id="returntrackingid" value="100" />
                                                    <input type="text" className="form-control" id="returntracking" value="12345678" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Shipping Method</label>
                                                    <select className="form-control" id="returnshipmethod">
                                                        <option value="">Please select</option>
                                                        <option value="3">FedEx</option>
                                                        <option value="2">UPS</option>
                                                        <option value="1">USPS</option>
                                                        <option value="18">4PX</option>
                                                        <option value="9">OnTrac</option>
                                                        <option value="63">China Post</option>
                                                        <option value="71">Estes</option>
                                                        <option value="70">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Shipping Date</label>
                                                    <input type="date" className="form-control" id="returnshipdate" value="2023-09-01" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>RMA#</label>
                                                    <input type="text" className="form-control" id="returnrma" value="1234" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 shipmethod-services">
                                                <div className="form-group">
                                                    <label>Shipping Services</label>
                                                    <input type="hidden" className="returnshipmethodservice" value="4" />
                                                    <select className="form-control" id="returnshipmethodservice">
                                                        <option value="0">Standard</option>
                                                        <option value="1">China Post e-Courier Packet</option>
                                                        <option value="2">China Post e-Courier Priority</option>
                                                        <option value="3">China Post e-EMS</option>
                                                        <option value="4">China Post ePacket</option>
                                                        <option value="5">China Post Ordinary Airmail</option>
                                                        <option value="6">China Post Registered Airmail</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer position-relative">
                                Order Remark
                                <div className='position-relative d-inline-block'>
                                    <a href="#" className="remark-status list-icons-item badge bg-success mr-1 ml-1 list-icons-item dropdown-toggle remove-dropdown-arrow" onMouseEnter={() => setProfit(1)} onMouseOut={() => setProfit(0)}>profit</a>
                                    <div className={'dropdown-menu dropdown-menu-left bg-info' + (profit == 1 ? ' show' : '')}>
                                        <div className="remark-wrap">
                                            <div className="px-2 d-flex justify-content-between">
                                                <div>
                                                    <p className="m-0">Buyer Paid:</p>
                                                    <p className="m-0">Cost:</p>
                                                    <p className="m-0">Amazon Fee:</p>
                                                    <p className="m-0"><b>Profit:</b></p>
                                                </div>
                                                <div>
                                                    <p className="m-0">143.43</p>
                                                    <p className="m-0">120</p>
                                                    <p className="m-0">21.51</p>
                                                    <p className="m-0"><b>1.92</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                Amazon Fees 15% = 21.51
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Order Status</h5>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <select className="form-control" id="orderSubStatus">
                                        <option value="15" data-status="RETURN-REFUNDED">RETURN-REFUNDED</option>
                                    </select>
                                </div>
                                <div className="form-group cancelReason-wrap hide">
                                    <input type="hidden" value="8" id="cancel-reason" />
                                    <select className="form-control" id="cancelReason"> </select>
                                </div>
                                <button id="updateOrder" className="btn btn-primary">Update Order</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">SHIPMENT TRACKING #</h5>
                            </div>
                            <div className="card-body">
                                <p>
                                    <a href="https://www.aftership.com/track/12345678" target="_blank">12345678</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Log</h5>
                            </div>
                            <div className="card-body">
                                <div className="list-feed">
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:30AM - testreg1</div>
                                        ITEM RETURNED REFUND
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:30AM - testreg1</div>
                                        ITEM RETURN - REPLACEMENT
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:23AM - testreg1</div>
                                        ITEM SHIPPED ORDER
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:20AM - testreg1</div>
                                        ITEM ORDERED
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:19AM - testreg1</div>
                                        ITEM STATE RESTRICTION
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:07AM - testreg1</div>
                                        ITEM CANCELLED
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:06AM - testreg1</div>
                                        ITEM ORDERED
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 6:06AM - testreg1</div>
                                        ITEM LOSSED
                                    </div>
                                    <div className="list-feed-item">
                                        <div className="text-muted">08/28/2023 5:51AM - testreg1</div>
                                        ITEM OOS
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-light">
                                <h5 className="card-title">Order Notes!</h5>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <textarea className="form-control" id="displaynotes" rows={5}>Product Notes</textarea>
                                </div>
                                <h5>Your Notes</h5>
                                <div className="form-group">
                                    <textarea className="form-control" id="writenotes" rows={3}>Your Notes edit</textarea>
                                </div>
                                <button className="btn btn-primary" id="saveNotes">Save Notes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails