import { useState } from 'react';
import axios from "axios";
import { NavLink, useOutletContext } from 'react-router-dom'

const LoginVA = () => {
    var api = useOutletContext();

    const [inputs, setInputs] = useState({
        user: '',
        pass: ''
    });

    const handleChange = (event:any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = (event:any) => {
        event.preventDefault();
        
        axios.post(api+'/server.php?action=loginva', inputs)
        .then((response) => {
            if(response.data === 'success'){
                window.location.href = '/dashboard';
            }
        });
    }

    return (
        <div className="content d-flex justify-content-center align-items-center">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <img src="assets/img/wholesaleX72.png" className="img-fluid" alt='WholeSaleX Logo'/>
                            <br/><br/>
                            <h5 className="mb-0">VA Login</h5>
                            <span className="d-block text-muted">Enter your credentials below</span>
                        </div>
                        
                        <div id="invalidCredentials" style={{textAlign: 'center', color: 'red'}}></div>
                        <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="test" className="form-control" name="user" placeholder="Username" value={inputs.user || ""} onChange={handleChange}/>
                            <div className="form-control-feedback">
                                <i className="icon-user text-muted" id="icondomz"></i>
                            </div>
                        </div>

                        <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="password" className="form-control" name="pass" placeholder="Password" value={inputs.pass || ""} onChange={handleChange}/>
                            <div className="form-control-feedback">
                                <i className="icon-lock2 text-muted" id="icondomz"></i>
                            </div>
                        </div>
                                                        
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-block legitRipple">Sign in <i className="icon-circle-right2 ml-2"></i></button>
                        </div>
                        <div className="text-center">
                            <NavLink to="/login">Main Login</NavLink>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LoginVA