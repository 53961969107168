import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

import Background from './assets/global_assets/images/backgrounds/panel_bg.png';
import Profile from './settings/Profile';
import BillingManagement from './settings/BillingManagement';
import ChangePassword from './settings/ChangePassword';

interface Props {}

const Settings = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Settings"
    }, []);

    const [activeTab, setActiveTab] = useState('Profile')

    return (
        <div className="content">
            <div className="row profile-page">
                <div className="col-sm-12 col-lg-4">
                    <div className="card">
                        <div className="card-body bg-indigo-400 text-center card-img-top" style={{backgroundImage: 'url('+Background+')', backgroundSize: 'contain', backgroundColor: '#0c5483'}}>
                            <div className="card-img-actions d-inline-block mb-3">
                                <div className="btn bg-success-400 rounded-round btn-icon btn-sm mr-2">
                                    <span className="letter-icon" style={{fontSize: '75px', height: '8.25002rem', width: '8rem'}}>WT</span>
                                </div>
                            </div>

                            <h6 className="font-weight-semibold mb-0">WHx Test</h6>
                        </div>

                        <div className="card-body p-0">
                            <ul className="nav nav-sidebar">
                                <li className="nav-item">
                                    <a href="#" className={'nav-link' + (activeTab == 'Profile' ? ' active' : '')} onClick={() => setActiveTab('Profile')}>
                                        <i className="icon-user"></i>
                                        My Profile
                                    </a>
                                </li>
                                <li className="nav-item-divider"></li>
                                <li className="nav-item">
                                    <a href="#" className={'nav-link' + (activeTab == 'BillingManagement' ? ' active' : '')} onClick={() => setActiveTab('BillingManagement')}>
                                        <i className="icon-coin-dollar"></i>
                                        Billing Management
                                    </a>
                                </li>
                                <li className="nav-item-divider"></li>
                                <li className="nav-item">
                                    <NavLink to="/va-management" className="nav-link"><i className="icon-users"></i> VA Management</NavLink>
                                </li>
                                <li className="nav-item-divider"></li>
                                <li className="nav-item">
                                    <a href="#" className={'nav-link' + (activeTab == 'ChangePassword' ? ' active' : '')} onClick={() => setActiveTab('ChangePassword')}>
                                        <i className="icon-key"></i>
                                        Change Password
                                    </a>
                                </li>
                                <li className="nav-item-divider"></li>
                                <li className="nav-item">
                                    <NavLink to="/logout" className="nav-link"><i className="icon-switch2"></i> Logout</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-lg-8">
                    {
                        activeTab == 'Profile'
                        ? <Profile></Profile>
                        : activeTab == 'BillingManagement'
                        ? <BillingManagement></BillingManagement>
                        : <ChangePassword></ChangePassword>
                    }
                </div>
            </div>
        </div>
    )
}

export default Settings