import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

interface Props {}

const VAManagement = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | VA Managament"
    }, []);

    const [revoke, setRevoke] = useState(false);
    const [remove, setRemove] = useState(false);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline"> 
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">VA Management</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div> 
                </div>
            </div>
            
            <div className='content'>
                <div className="row">
                    <div className="col-md-9">
                        <h4>VA LISTS:</h4>
                        <div className="card br-10">
                            <div className="table-responsive">
                                <table className="table table-lg text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>FULL NAME</th>
                                            <th>EMAIL</th>
                                            <th>USERNAME</th>
                                            <th>ROLE</th>
                                            <th>STATUS</th>
                                            <th style={{width: '90px'}}>ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Jay</td>
                                            <td>jrmagarso@gmail.com</td>
                                            <td>jay</td>
                                            <td>admin</td>
                                            <td>
                                                revoked
                                            </td>
                                            <td>
                                                <a href="#" className='mr-1'><i className="icon-pencil7 text-info"></i></a>
                                                <a href="#" onClick={() => setRevoke(true)} className='mr-1'><i className="icon-blocked text-warning"></i></a>
                                                <a href="#" onClick={() => setRemove(true)}><i className="icon-trash text-danger"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>rod</td>
                                            <td>rod@gmail.com</td>
                                            <td>rod</td>
                                            <td>inventory</td>
                                            <td>
                                                active
                                            </td>
                                            <td>
                                                <a href="#" className='mr-1'><i className="icon-pencil7 text-info"></i></a>
                                                <a href="#" onClick={() => setRevoke(true)} className='mr-1'><i className="icon-blocked text-warning"></i></a>
                                                <a href="#" onClick={() => setRemove(true)}><i className="icon-trash text-danger"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>domz</td>
                                            <td>dom@gmail.com</td>
                                            <td>domz</td>
                                            <td>order</td>
                                            <td>
                                                active
                                            </td>
                                            <td>
                                                <a href="#" className='mr-1'><i className="icon-pencil7 text-info"></i></a>
                                                <a href="#" onClick={() => setRevoke(true)} className='mr-1'><i className="icon-blocked text-warning"></i></a>
                                                <a href="#" onClick={() => setRemove(true)}><i className="icon-trash text-danger"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <h4>LOGS HISTORY:</h4>
                        <div className="card br-10 logs-wrap">
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead>
                                        <tr>
                                            <th style={{width: '100px'}}>DATE</th>
                                            <th style={{width: '50px'}}>USER</th>
                                            <th style={{width: '100px'}}>ACTION</th>
                                            <th>DETAILS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11/22/2023 04:10:47PM</td>
                                            <td>testreg1</td>
                                            <td>Cancel Auto Order</td>
                                            <td className="details">Order ID: 114-4438304-3428266</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2023 04:08:06PM</td>
                                            <td>testreg1</td>
                                            <td>Set Auto Order</td>
                                            <td className="details">Order ID: 114-4438304-3428266</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2023 04:06:15PM</td>
                                            <td>testreg1</td>
                                            <td>Cancel Auto Order</td>
                                            <td className="details">Order ID: 114-4438304-3428266</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2023 04:06:01PM</td>
                                            <td>testreg1</td>
                                            <td>Set Auto Order</td>
                                            <td className="details">Order ID: 114-4438304-3428266</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2023 04:04:21PM</td>
                                            <td>testreg1</td>
                                            <td>Update Order Settings</td>
                                            <td className="details">["21","17"]</td>
                                        </tr>
                                        <tr>
                                            <td>11/22/2023 04:04:06PM</td>
                                            <td>testreg1</td>
                                            <td>Update Order Settings</td>
                                            <td className="details">["21","17"]</td>
                                        </tr>
                                        <tr>
                                            <td>11/19/2023 01:14:04AM</td>
                                            <td>testreg1</td>
                                            <td>Update Order Settings</td>
                                            <td className="details">["21","17"]</td>
                                        </tr>
                                        <tr>
                                            <td>11/19/2023 01:13:50AM</td>
                                            <td>testreg1</td>
                                            <td>Update Order Settings</td>
                                            <td className="details">["21","17"]</td>
                                        </tr>
                                        <tr>
                                            <td>10/25/2023 05:51:57AM</td>
                                            <td>testreg1</td>
                                            <td>catalogDeleteBulkUpload</td>
                                            <td className="details"></td>
                                        </tr>
                                        <tr>
                                            <td>10/25/2023 05:45:47AM</td>
                                            <td>testreg1</td>
                                            <td>catalogDeleteBulkUpload</td>
                                            <td className="details"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <p className="mb-0">Total of 292 entries.</p>
                        <div className="pagination mb-3">
                            <span className="btn" style={{backgroundColor: '#e1e1e1'}}>1</span>&nbsp;<a href="?loadpage=2&show=10"><button className="btn btn-primary">2</button></a>&nbsp;
                            <a href="?loadpage=3&show=10"><button className="btn btn-primary">3</button></a>&nbsp;<a href="?loadpage=4&show=10"><button className="btn btn-primary">4</button></a>&nbsp;
                            <a href="?loadpage=5&show=10"><button className="btn btn-primary">5</button></a>&nbsp;<a href="?loadpage=6&show=10"><button className="btn btn-primary">6</button></a>&nbsp;
                            <a href="?loadpage=2&show=10"><button className="btn btn-primary">Next</button></a>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <h4>ADD ACCOUNT:</h4>
                        <div className="card br-10 p-3">
                            <form method="POST" action="/management_va/index.php">
                                <div className="form-group">
                                    <input type="text" name="fname" className="form-control" placeholder="Fullname" />
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" className="form-control" placeholder="email" />
                                </div>
                                <div className="form-group">
                                    <select className="form-control" name="role">
                                        <option value="select">Select Role</option>
                                        <option value="admin">Admin</option>
                                        <option value="inventory">Inventory Manager</option>
                                        <option value="order">Order Manager</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="usern" className="form-control" placeholder="Username" />
                                </div>
                                <div className="form-group mb-0 text-right">
                                    <button type="submit" name="submit" className="btn btn-primary"><i className="icon-user-plus"></i> Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={revoke} onHide={() => setRevoke(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to Revoke user account?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setRevoke(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={remove} onHide={() => setRemove(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to Remove user account?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setRemove(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VAManagement