import { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

interface Props {}

const Knowledgebase = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Knowledgebase"
    }, []);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Support</a>
                            <a href="#" className="breadcrumb-item">Knowledge Base</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="text-center mb-3 py-2">
                    <h4 className="font-weight-semibold mb-1">Welcome to our knowledge base</h4>
                    <span className="text-muted d-block">
                        Our knowledge base aims to educate users on how to navigate and use WholesaleX. Mainly to help find answers to solve problems on their own, eliminate confusion and maximize the tool's potential.
                    </span>
                </div>

                <div className="mb-3">
                    <h6 className="mb-0 font-weight-semibold">
                        Latest videos
                    </h6>
                    <span className="text-muted d-block">Video Tutorials</span>
                </div>

                <div className="row" id="listVideos">
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <iframe width="320" height="176" src="https://www.youtube.com/embed/g4GI1hwpHmA" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">WholesaleX</div>
                                        <span className="font-size-sm text-muted">Overview of WholesaleX</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <video width="320" height="176" className="embed-responsive-item">
                                        <source src="/management/public/uploads/knowledgebase/1695950852__WHxSPAPI1.mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">Amazon SP-API Integration</div>
                                        <span className="font-size-sm text-muted">How to authorize WholesaleX with Amazon SP-API.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <video width="320" height="176" className="embed-responsive-item">
                                        <source src="/management/public/uploads/knowledgebase/1695951051__WHxsuppliersetup.mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">WholesaleX Supplier Setup</div>
                                        <span className="font-size-sm text-muted">How to setup your supplier in WholesaleX.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <video width="320" height="176" className="embed-responsive-item">
                                        <source src="/management/public/uploads/knowledgebase/1695951274__WHxFeed.mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">Feed Overview under WholesaleX Inventory</div>
                                        <span className="font-size-sm text-muted">This video briefly explains how to utilize Feed under Inventory.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <video width="320" height="176" className="embed-responsive-item">
                                        <source src="/management/public/uploads/knowledgebase/1695951336__WHxQueue.mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">Queue Overview under WholesaleX Inventory</div>
                                        <span className="font-size-sm text-muted">This video briefly explains how to utilize Queue under Inventory.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card">
                            <div className="card-img-actions mx-1 mt-1">
                                <div className="card-img embed-responsive embed-responsive-16by9">
                                    <video width="320" height="176" className="embed-responsive-item">
                                        <source src="/management/public/uploads/knowledgebase/1698093879__FeedBulkUpload.mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-start flex-nowrap">
                                    <div>
                                        <div className="font-weight-semibold mr-2">How to upload CSV Inventory file to WholesaleX.</div>
                                        <span className="font-size-sm text-muted">This video will show you how to upload products from other suppliers to Feed Inventory of WholesaleX.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Knowledgebase