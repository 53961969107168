import { useEffect } from 'react'
import { LineChart } from '@mui/x-charts/LineChart';

interface Props {}

const Dashboard = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Dashboard";
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Daily Sales Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Weekly Sales Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Monthly Sales Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card br-10">
                        <div className="card-body">
                            <strong>Profit Report</strong>
                            <div className="chart-container">
                                <LineChart
                                    xAxis={[{ 
                                        scaleType: 'point',
                                        data: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                    }]}
                                    series={[{
                                        data: [2, 5, 4, 6.5, 5, 6, 9],
                                        color: '#4e79a7b0',
                                        area: true
                                    }]}
                                    height={250}
                                    margin={{
                                        left: 25,
                                        right: 22,
                                        top: 20,
                                        bottom: 20,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Daily Profit Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Weekly Profit Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card br-10">
                                <div className="card-body">
                                    <strong>Monthly Profit Report</strong><br />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h1 className="m-0 text-info"><strong>$0</strong></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card br-10">
                                <div className="card-body overflow-hidden">
                                    <strong>Top Selling Items</strong>
                                    <table className="table overflow-auto d-block">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="pl-1">Supplier</th>
                                                <th>Product Name</th>
                                                <th>Price</th>
                                                <th className="text-nowrap">Total Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td className="pl-1"></td>
                                                <td>BestAir A201-SGM-BOX-11R AC Furnace Air Filter, 20" x 25" x 6", MERV 11, with Cardboard Frame, Fits 100%, For Aprilaire/SpaceGard 2200, 2250 (201) &amp; Lennox PMAC-20C 24", Pack of 2</td>
                                                <td>$102.95</td>
                                                <td>$1,152.35</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td className="pl-1"></td>
                                                <td>CRAFTSMAN 2000 Series 26-in W x 19.75-in H 5-Drawer Steel Tool Chest (Red)</td>
                                                <td>$259.97</td>
                                                <td>$3,119.69</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td className="pl-1"></td>
                                                <td>Lumber Jack 100% Hickory BBQ Grilling Pellets - 20 lbs.</td>
                                                <td>$69.08</td>
                                                <td>$446.79</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td className="pl-1"></td>
                                                <td>Lumber Jack Competition Blend Maple-Hickory-Cherry BBQ Grilling Pellets - 20 lbs.</td>
                                                <td>$31.97</td>
                                                <td>$249.70</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td className="pl-1"></td>
                                                <td>Lumber Jack Pecan Blend BBQ Grilling Pellets - 20 lbs.</td>
                                                <td>$30.88</td>
                                                <td>$247.09</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card br-10">
                        <div className="card-body">
                            <strong>Inventory</strong>
                            <div className="chart-container">
                                <LineChart
                                    xAxis={[{ 
                                        scaleType: 'point',
                                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] 
                                    }]}
                                    series={[
                                        {label: 'OK Products', data: [2, 5, 2, 8, 1, 5, 3], color: '#4e79a7'},
                                        {label: 'Price Change', data: [1, 2, 3, 4, 5, 6, 7], color: '#f28e2c'},
                                        {label: 'Back in Stock', data: [3, 1, 2, 3, 5, 2, 5], color: '#4caf50'},
                                        {label: 'OOS', data: [2, 7, 5, 1, 4, 3, 6], color: '#e15759'},
                                        {label: 'Close Listing', data: [4, 3, 7, 2, 6, 5, 1], color: '#777'},
                                    ]}
                                    height={250}
                                    margin={{
                                        left: 20,
                                        right: 10,
                                        top: 50,
                                        bottom: 20,
                                    }}
                                    slotProps={{
                                        legend: {
                                            labelStyle: {
                                                fontSize: 12
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card br-10">
                        <div className="card-body pt-2 pb-2">
                            <table className="table">
                                <thead className="text-nowrap">
                                    <tr>
                                        <th className="w-100">Suppliers</th>
                                        <th>Items in Catalog</th>
                                    </tr>
                                </thead>
                                <tbody className="text-info">
                                    <tr>
                                        <td>
                                            <strong><a href="https://ctwhomecollection.com/" target="_blank" className="text-info">CTW Home Collection</a></strong>
                                        </td>
                                        <td><strong>0</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong><a href="https://www.ckbproducts.com" target="_blank" className="text-info">CKB Products</a></strong>
                                        </td>
                                        <td><strong>1</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong><a href="https://www.bangalla.com" target="_blank" className="text-info">Bangalla</a></strong>
                                        </td>
                                        <td><strong>0</strong></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong><a href="https://www.aewholesale.com" target="_blank" className="text-info">AEWholesale</a></strong>
                                        </td>
                                        <td><strong>3</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard