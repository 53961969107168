import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

interface Props {}

const FAQ = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | FAQ"
    }, []);

    const [activeIndex, setActiveIndex] = useState(-1);

    const faqs = [
        {
            id: 1,
            question: "What is WholesaleX?",
            answer: "WholesaleX (WHx) is a powerful Sourcing, Listing and Inventory Management tool. Finding profitable products to sell online and managing your Amazon Inventory is easier with WholesaleX (WHx). We are constantly adding new suppliers and new features to anticipate and deliver what you need to succeed."
        },
        {
            id: 2,
            question: "How does it work?",
            answer: "WholesaleX has a built-in product sourcing tool to find profitable products faster. The products you have selected will be listed to your Amazon Inventory in just a few minutes. And, WholesaleX monitors your product's status and updates your Amazon Inventory automatically."
        },
        {
            id: 3,
            question: "How to setup a WholesaleX account?",
            answer: "You can setup an account by visiting the WholesaleX website."
        },
        {
            id: 4,
            question: "Do you have free Demo/Training?",
            answer: "Yes, we have tutorial videos under the Support tab of every registered WholesaleX account."
        },
        {
            id: 5,
            question: "Do you have a free trial?",
            answer: "Yes, WholesaleX has a 7-day free trial period."
        },
        {
            id: 6,
            question: "How does WholesaleX determine the item matching between the Supplier and Amazon?",
            answer: "WholesaleX uses the item's UPC ( Universal Product Code ) to determine the matches between the Supplier and Amazon. In cases with items without UPC, WholesaleX calculates a percentage value indicating the level of similarity between the Supplier's item Name/Title and Amazon's item Name/Title."
        },
        {
            id: 7,
            question: "How to reach/contact the WholesaleX Support team?",
            answer: "You may open a ticket to submit your questions or concerns in the My Tickets under the Support tab. Our support staff is always available to assist."
        },
        {
            id: 8,
            question: "How does WholesaleX update the Inventory of the supported Suppliers?",
            answer: "WholesaleX updates the Product status every week upon receiving the updated Inventory File from the supported SUppliers."
        }
    ];

    function handleClick(key:any) {
        setActiveIndex(activeIndex == key ? -1 : key)
    }

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb"> 
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Support</a>
                            <a href="#" className="breadcrumb-item">FAQ</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="text-center mb-3 py-2">
                    <h4 className="font-weight-semibold mb-1">All questions are answered</h4>
                    <span className="text-muted d-block">You may create a ticket for questions that are not found here.</span>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-8 m-auto">
                            <div className="card-group-control card-group-control-right">
                                {faqs.map((faq,key) => (
                                    <div className="card mb-2 br-10" key={key}>
                                        <div className="card-header">
                                            <h6 className="card-title">
                                                <a 
                                                    onClick={() => handleClick(key)}
                                                    aria-controls={'faq'+key}
                                                    aria-expanded={(activeIndex == key ? true : false)}
                                                    className="text-default" href={'#faq'+key}
                                                    > 
                                                        <i className="icon-help mr-2 text-slate"></i> {faq.question}
                                                </a>
                                            </h6>
                                        </div>
                                        
                                        <Collapse className={(activeIndex == key ? 'show' : '')}>
                                            <div id={'faq'+key}>
                                                <div className="card-body">
                                                    {faq.answer}
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ