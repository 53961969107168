import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import './components/assets/global_assets/css/icons/icomoon/styles.min.css';
import './components/assets/css/bootstrap.min.css';
import './components/assets/css/bootstrap_limitless.min.css';
import './components/assets/css/layout.min.css';
import './components/assets/css/components.min.css';
import './components/assets/css/colors.min.css';
import './App.css';
import Sidebar from './components/includes/Sidebar';
import Navbar from './components/includes/Navbar';
import Footer from './components/includes/Footer';

function App() {
    const navigate = useNavigate();

    useEffect(() => {
        var userData = localStorage.getItem('userData');
        
        if(!userData){
            navigate('/login');
        }
    }, []);

    return (
        <>
            <Navbar />
            <div className="page-content"> 
                <Sidebar />

                <div className="content-wrapper">
                    <Outlet context='https://reactjs.wholesalex.app/api' />

                    <Footer />
                </div>
            </div>
        </>
    );
}

export default App;