import { useState } from 'react';
import axios from "axios";
import { NavLink, useOutletContext } from 'react-router-dom'

const ForgotPassword = () => {
    var api = useOutletContext();

    const [inputs, setInputs] = useState('');

    const handleChange = (event:any) => {
        const value = event.target.value;
        setInputs(value)
    }

    const handleSubmit = (event:any) => {
        event.preventDefault();

        axios.post(api+'/server.php?action=forgot_password', inputs)
        .then((response) => {
            console.log(response.data);
        });
    }
    return (
        <div className='content d-flex justify-content-center align-items-center'>
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <div style={{padding: '20px'}}><img src="assets/img/wholesaleX72.png" className="img-fluid"/></div>
                            
                            <h5 className="mb-0">Retrieve your account</h5>
                            
                            <span className="d-block text-muted">Your username or email</span>
                        </div>
                        
                        <div id="invalidCredentials" style={{textAlign: 'center', color: 'red'}}></div>

                        <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="text" className="form-control" name="username-email" placeholder="Username or Account Email" value={inputs || ""} onChange={handleChange}/>
                            <div className="form-control-feedback">
                                <i className="icon-user text-muted"></i>
                            </div>
                        </div>

                        <div className="form-group text-center">
                            <NavLink to="/login" className="btn btn-primary " style={{width: '48%'}}><i className="icon-circle-left2 mr-2"></i>Back</NavLink>&nbsp;&nbsp;
                            <button type="submit" name="submit" id="forgotpass" style={{width: '48%'}} className="btn btn-primary">Submit<i className="icon-circle-right2 ml-2"></i></button>
                        </div>

                        <span className="form-text text-center text-muted">By continuing, you're confirming that you've read our <a href="https://wholesalex.app/termsandconditions.html" target="_blank">Terms of Service</a> and <a href="https://wholesalex.app/privacyandpolicy.html" target="_blank">Privacy Policy</a></span>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ForgotPassword