import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUpload, faCheckCircle, faWarning, faPencilSquare, faBan, faTruck } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const Catalog = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Catalog"
    }, []);

    const [addToCloseListingMultiple, setAddToCloseListingMultiple] = useState(false);
    const [editProduct, setEditProduct] = useState(false);
    const [addToCloseListing, setAddToCloseListing] = useState(false);
    const [PL, set3PL] = useState(false);

    const [activeStats, setActiveStats] = useState(0);
    const [activeIndex, setActiveIndex] = useState(-1);

    const catalog_prods = [
        {
            id: 1,
            supplier: "ctw",
            product: "Pendant Lamp Canopy Kit",
            sku: 'AEW5950QYWA',
            asin: 'B00LENQYWA',
            total_cost: 0,
            qty: 1,
            amz_match: 2,
            status: 1
        }
    ]

    function mouseHandler(key:any) {
        setActiveStats(key.stat)
        setActiveIndex(key.key)
    }

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Inventory</a>
                            <a href="#" className="breadcrumb-item">Catalog</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <span className="btn btn-light">Status</span>
                                </span>
                                <select className="form-control listings-status" id="catalogStatus">
                                    <option value="">- All -</option>
                                    <option value="ok_products">Ok Products</option>
                                    <option value="price_change">Price Change</option>
                                    <option value="back_in_stock">Back in Stock</option>
                                    <option value="out_of_stock">Out Of Stock</option>
                                    <option value="close_listings">Close Listings</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <form method="GET">
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="text" name="search" id="searchVal" className="form-control" />
                                    <span className="input-group-btn">
                                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faSearch} /> Search</button>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group text-right">
                            <button className="btn btn-primary" onClick={() => setAddToCloseListingMultiple(true)}>Add to Close Listing</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <span className="float-right">
                            Legend: <FontAwesomeIcon icon={faUpload} className='text-info' /> - Uploading to Amazon | <FontAwesomeIcon icon={faCheckCircle} className='text-success' /> - Added to Amazon | <FontAwesomeIcon icon={faWarning} className='text-danger' /> - Restricted Brand
                        </span>
                    </div>
                </div>
                
                <div className="row mt-1">
                    <div className="col-md-12 position-relative">
                        <div className='position-absolute' style={{marginTop: '3px'}}>
                            <div className="d-flex align-items-center mr-2">
                                <label className="m-0 mr-1">Show: </label>
                                <select className="form-control" style={{width: '70px'}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>

                        <Tabs
                            defaultActiveKey="single"
                            id="tabs"
                            className="mb-0"
                            style={{marginLeft: '115px'}}
                            >
                            <Tab eventKey="single" title={<strong>Single Listings</strong>}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body p-0 overflow-x-auto">
                                                <div style={{overflowY: 'auto',minHeight: '620px',maxHeight: '610px'}}>
                                                    <table className="table table-xs listing-table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center"><input type="checkbox" className="control-input" /></th>
                                                                <th>SUPPLIER</th>
                                                                <th style={{minWidth: '200px'}}>PRODUCT</th>
                                                                <th>SKU</th>
                                                                <th>ASIN</th>
                                                                <th className="text-nowrap">TOTAL COST</th>
                                                                <th>QTY</th>
                                                                <th>STATUS</th>
                                                                <th>3PL</th>
                                                                <th style={{minWidth: '50px'}}>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {catalog_prods.map((catalog,key) => (
                                                                <tr key={key}>
                                                                    <td className='text-center'>
                                                                        <input type="checkbox" className="control-input" id="checkfeedsitem" value="86" />
                                                                    </td>
                                                                    <td>AEW</td>
                                                                    <td>
                                                                        Cement Flower Pot Set - Taupe Scallop Design
                                                                    </td>
                                                                    <td>4506516</td>
                                                                    <td>B005T9B7H2</td>
                                                                    <td className='position-relative'>
                                                                        <a href="#" className="list-icons-item dropdown-toggle text-info remove-dropdown-arrow" onMouseEnter={() => mouseHandler({key: 0, stat: 1})} onMouseOut={() => mouseHandler({key: 0, stat: 0})}>$26.90</a>
                                                                        <div className={'dropdown-menu dropdown-menu-right bg-info' + (activeIndex == key && activeStats == 1 ? ' show' : '')}>
                                                                            <div className="p-2">
                                                                                <p className="m-0">Listing Price: $13.25</p>
                                                                                <p className="m-0">Tax Rate: $0.40 (3%)</p>
                                                                                <p className="m-0">Supplier Shipping: $8.25</p>
                                                                                <p className="m-0">3PL Shipping: $5</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>50</td>
                                                                    <td>
                                                                        <i className="fa fa-upload"></i>
                                                                    </td>
                                                                    <td>
                                                                        <i className="fa fa-check-circle text-success"></i>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                            <Dropdown.Item href="#" onClick={() => setEditProduct(true)}><FontAwesomeIcon icon={faPencilSquare} className='mr-2' /> Edit</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => setAddToCloseListing(true)}><FontAwesomeIcon icon={faBan} className='mr-2 text-danger' /> Add to Close Listing</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => set3PL(true)}><FontAwesomeIcon icon={faTruck} className='mr-2 text-success' /> 3PL</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="mb-0">Total of 1 entries</p>
                                        <div className="pagination"></div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="multipack" title={<strong>Multipack Listings</strong>}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body p-0 overflow-x-auto">
                                                <div style={{overflowY: 'auto',minHeight: '620px',maxHeight: '610px'}}>
                                                    <table className="table table-xs listing-table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center"><input type="checkbox" className="control-input" /></th>
                                                                <th>SUPPLIER</th>
                                                                <th style={{minWidth: '200px'}}>PRODUCT</th>
                                                                <th>SKU</th>
                                                                <th>ASIN</th>
                                                                <th className="text-nowrap">TOTAL COST</th>
                                                                <th>QTY</th>
                                                                <th>STATUS</th>
                                                                <th>3PL</th>
                                                                <th style={{minWidth: '50px'}}>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="mb-0">Total of 1 entries</p>
                                        <div className="pagination"></div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal show={addToCloseListingMultiple} onHide={() => setAddToCloseListingMultiple(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to add the items to Close Listing?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setAddToCloseListingMultiple(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={addToCloseListing} onHide={() => setAddToCloseListing(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to add the items to Close Listing?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setAddToCloseListing(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={editProduct} onHide={() => setEditProduct(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Edit Product
                        <button type="button" className='close' onClick={() => setEditProduct(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-4 col-sm-12">
                            <div className="form-group">
                                <label>TITLE</label>
                                <input id="vcttitle" className="form-control" value="Cement Flower Pot Set - Taupe Scallop Design" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>BRAND</label>
                                <input id="vctbrand" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>CATEGORY</label>
                                <input id="vctcategory" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>SKU</label>
                                <input id="vctsku" className="form-control" value="4506516" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>ASIN</label>
                                <input id="vctasin" className="form-control" value="B005T9B7H2" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-12">
                            <div className="form-group">
                                <label>Description:</label>
                                <textarea id="vctdesc" className="form-control" rows={4}></textarea>
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>UPC</label>
                                <input id="vctupc" className="form-control" value="8.49179E+11" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>MAP</label>
                                <input id="vctmap" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>MSRP</label>
                                <input id="vctmsrp" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>PRICE</label>
                                <input id="vctprice" className="form-control" value="13.25" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-4">
                            <div className="form-group">
                                <label>LISTING PRICE</label>
                                <input id="vctlistedprice" className="form-control" value="26.90" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-4">
                            <div className="form-group">
                                <label>SHIPPING</label>
                                <input id="vctshipping" className="form-control" value="8.25" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setEditProduct(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={PL} onHide={() => set3PL(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Integrate Two Step Drop Shipping
                        <button type="button" className='close' onClick={() => set3PL(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>Setup</label>
                                <select className="form-select form-control" aria-label="Default select example" id="is3pl">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>Handling Time</label>
                                <input id="3plhandlingtime" className="form-control" value="5" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>Shipping</label>
                                <input id="3plshipping" className="form-control" value="5" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>On Hand Qty</label>
                                <input id="3plqty" className="form-control" value="50" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => set3PL(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Catalog