import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {}

const Forum = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Forum";
    }, []);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline"> 
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Forum</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="content">
                <form>
                    <input type="hidden" name="page" value="forum" />
                    <div className="row">
                        <div className="col mb-2">
                            <div className="input-group align-items-center">
                                Search:
                                <input type="text" name="search" value="" placeholder="Enter keyword..." className="form-control ml-1" />
                            </div>
                        </div>
                        <div className="col-lg-auto mb-2">
                            <div className="row">
                                <div className="col mb-3">
                                    <div className="input-group align-items-center">
                                        Category:
                                        <select className="form-control ml-1" name="category">
                                            <option value="">All</option>
                                            <option value="Discussion">Discussion</option>
                                            <option value="Question">Question</option>
                                            <option value="Idea">Ideas</option>
                                            <option value="Article">Articles</option>
                                            <option value="Event">Events</option>
                                            <option value="Issue">Issues</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-auto mb-3">
                                    <button type="submit" className="btn btn-primary"><i className="icon-search4"></i> Search</button>
                                    <button type="button" data-toggle="modal" data-target="#newThread" className="btn btn-primary ml-1 thread-action" data-id="" data-action="add"><i className="icon-plus2"></i> New</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="tab" value="my-forum" id="active-tab" />
                </form>

                <div className="card br-10 forum-page">
                    <ul className="nav nav-tabs mb-0" style={{background: '#e5e5e5', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
                        <li className="nav-item" data-tab="my-forum">
                            <a className="nav-link active" href="#my-forum" data-toggle="tab" data-status="my-forum" style={{borderTopLeftRadius: '6px'}}>
                                <h5 className="my-1"><strong>My Forum</strong></h5>
                            </a>
                        </li>
                        <li className="nav-item" data-tab="global-forum">
                            <a className="nav-link" href="#global-forum" data-toggle="tab" data-status="global-forum">
                                <h5 className="my-1"><strong>Forum</strong></h5>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content p-3" id="tabs">
                        <div className="tab-pane fade active in show" id="my-forum">
                            <div className="card br-10">
                                <div className="table-responsive">
                                    <table className="table text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>THREAD</th>
                                                <th style={{width: 50}}>&nbsp;</th>
                                                <th>LAST POST BY</th>
                                                <th style={{width: 140}}>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} className="text-center">No data found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane" id="global-forum">
                            <div className="row">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="card br-10">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>THREAD</th>
                                                        <th style={{width: 50}}>&nbsp;</th>
                                                        <th>LAST POST BY</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={3} className="text-center">No data found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="card br-10">
                                        <div className="card-body">
                                            <h5 className="mb-0">TRENDING <i className="fa fa-fire text-danger"></i></h5>
                                            <div className="d-flex mt-2 mb-2">
                                                <h6>Start using Forum. Sharing is caring!</h6>
                                            </div>

                                            <h5 className="mb-1 mt-3">TOP CREATORS</h5>
                                            <ul className="m-0 p-0 list-unstyled"></ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forum