import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const OrderManagement = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Order Management"
    }, []);

    const [settings, setSettings] = useState(false);
    const [shipDetails1, setShipDetails1] = useState(false);
    const [shipDetails2, setShipDetails2] = useState(false);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Order Management</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content order-page">
                <div className="row mb-2">
                    <div className="col text-right">
                        <a href="#" className="btn bg-success-400 rounded-round" onClick={() => setSettings(true)}>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faGear} className='fa-2x' />
                                <h4 className="m-0 pl-2">Settings</h4>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6 col-xl-3">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-notification2 icon-3x text-primary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">86</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Late Orders</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-warning2 icon-3x text-primary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">0</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Orders Due Today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-cart-add2 icon-3x text-primary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">0</h3>
                                    <span className="text-uppercase font-size-sm text-muted">New Orders Today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="card card-body br-10">
                            <div className="media">
                                <div className="mr-3 align-self-center">
                                    <i className="icon-bag icon-3x text-primary"></i>
                                </div>
                                <div className="media-body text-right">
                                    <h3 className="font-weight-semibold mb-0">0</h3>
                                    <span className="text-uppercase font-size-sm text-muted">Past 30 Days Orders</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card br-10">
                    <form method="GET" className="search-form">
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-12 col-xl-8">
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-2">
                                            <div className="form-group">
                                                <label className="form-label">Auto Order:</label>
                                                <input type="hidden" name="page" value="order" />
                                                <input type="hidden" name="search" value="" className="search-order" />
                                                <select className="custom-select" name="autorder" id="autorder-order">
                                                    <option value="">All</option>
                                                    <option value="1">Auto</option>
                                                    <option value="0">Manual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-2">
                                            <div className="form-group">
                                                <label className="form-label">3PL:</label>
                                                <select className="custom-select" name="3pl" id="3pl-order">
                                                    <option value="">All</option>
                                                    <option value="0">None 3PL</option>
                                                    <option value="1">3PL</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-2">
                                            <div className="form-group">
                                                <label className="form-label">Status:</label>
                                                <select className="custom-select" name="status" id="status-order">
                                                    <option value="">All</option>
                                                    <option value="0">Pending</option>
                                                    <option value="6">Ordered</option>
                                                    <option value="3">Shipped</option>
                                                    <option value="8">Cancelled</option>
                                                    <option value="30">Request Return</option>
                                                    <option value="5">Refunded</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-2">
                                            <div className="form-group">
                                                <label className="form-label">Supplier:</label>
                                                <select className="custom-select" name="supplier" id="supplier-order">
                                                    <option value="">All</option>
                                                    <option value="aew">AEWholesale</option>
                                                    <option value="ckb">CKB Products</option>
                                                    <option value="ctw">CTW Home Collection</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="form-label">Date Range:</label>
                                                <div id="reportrange" className="form-control" style={{minWidth: '215px'}}><span>MM/DD/YYYY - MM/DD/YYYY</span> <i className="fa fa-caret-down"></i></div>
                                                <input type="hidden" name="datefrom" id="datefrom" value="" />
                                                <input type="hidden" name="dateto" id="dateto" value="" />
                                                <input type="hidden" name="show" value="10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-4">
                                    <label className="form-label empty-label">&nbsp;</label>
                                    <div className="input-group d-block text-right mb-3">
                                        <button type="submit" className="btn btn-primary mr-1">Search</button>
                                        <a href="index.php?page=order" className="btn btn-primary mr-1">Clear</a>
                                        <button type="button" className="btn btn-primary" id="export-orders">Export CSV</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="d-flex align-items-center mb-1">
                    <div className="w-auto d-flex align-items-center mr-2">
                        <label className="m-0 mr-1">Show: </label>
                        <select className="form-control" style={{width: '66px'}}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>

                    <input type="text" className="form-control" id="searchVal" placeholder="Search for product name, sku, orderid" />
                </div>

                <div className="card">
                    <div className="card-body p-0 overflow-x-auto">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center pl-3"><i className="icon-arrow-down15"></i></th>
                                    <th>DATE</th>
                                    <th>STATUS</th>
                                    <th className="text-nowrap">PRODUCT DETAILS</th>
                                    <th>TRACKING #</th>
                                    <th>SHIP TO INFO</th>
                                    <th>QTY</th>
                                    <th>PRICE</th>
                                    <th className="text-nowrap">BUYER PAID</th>
                                    <th className="text-nowrap pr-3">NET PROFIT</th>
                                    <th className="text-nowrap pr-3">PLACE ORDER</th>
                                    <th className="text-nowrap pr-3">AUTO ORDER</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bordered-blue">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/25/2023</span> <span>9:32PM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-warning">REFUNDED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>CoscoProducts Deluxe 8 foot x 30 inch Fold-in-Half Blow Molded Folding Table, White</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1445001</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/12345678" target="_blank">12345678</a>
                                    </td>
                                    <td className="pb-1 position-relative">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" onClick={() => setShipDetails1(!shipDetails1)}>View Details</a>
                                        <div className={'dropdown-menu dropdown-content bg-info' + (shipDetails1 ? ' show' : '')} x-placement="bottom-end">
                                            <div className="p-2 text-nowrap">
                                                <p className="m-0">Buyer Name: <b>Melanie Foret</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 480-618-5344 ext. 10974</b></p>
                                                <p className="m-0">Buyer Email: <b>sncmhjz14t9lxx5@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Melanie Foret</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 480-618-5344 ext. 10974</b></p>
                                                <p className="m-0">Recipient Address: <b>445 AVENUE C, WESTWEGO, LA, US 70094-3612</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$120</td>
                                    <td className="pb-1">$143.43</td>
                                    <td className="pb-1 pr-3"><span className="">$1.92</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-times-circle fa-2x text-danger"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="99" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue bg-light">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/25/2023</span> <span>11:15AM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-info">PENDING</span></NavLink> 
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Tough-1 Hay Hoops Wall Hay Feeder with Net Red</span>
                                        <b>SUPPLIER:</b> <span className="text-info">aew</span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">1286029</span>
                                    </td>
                                    <td className="pb-1"></td>
                                    <td className="pb-1 position-relative">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" onClick={() => setShipDetails2(!shipDetails2)}>View Details</a>
                                        <div className={'dropdown-menu dropdown-menu-right bg-info' + (shipDetails2 ? ' show' : '')} x-placement="bottom-end">
                                            <div className="p-2 text-nowrap">
                                                <p className="m-0">Buyer Name: <b>Jeanne Coleman</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 929-436-4790 ext. 89861</b></p>
                                                <p className="m-0">Buyer Email: <b>yrgnhwhw1bwss9n@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Jeanne Coleman</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 929-436-4790 ext. 89861</b></p>
                                                <p className="m-0">Recipient Address: <b>127 OLD FERRY RD, WEST CHESTERFIELD, NH, US 03466-3013</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">2</td>
                                    <td className="pb-1"></td>
                                    <td className="pb-1">$121.66</td>
                                    <td className="pb-1 pr-3"><span className="">$121.66</span></td>
                                    <td className="pb-1">
                                        <a href="https://www.aewholesale.com/cart.php" target="_blank"><i className="icon-cart icon-2x text-primary"></i></a>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="98" data-autorder="1"><i className="fa fa-3x fa-toggle-on"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/24/2023</span> <span>10:24AM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-primary">ORDERED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Tough-1 Hay Hoops Wall Hay Feeder with Net Red</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">1286029</span>
                                    </td>
                                    <td className="pb-1"></td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Miles</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 314-282-9402 ext. 23171</b></p>
                                                <p className="m-0">Buyer Email: <b>l9gt2xns94m0h1g@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Miles</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 314-282-9402 ext. 23171</b></p>
                                                <p className="m-0">Recipient Address: <b>715 ELLIE NAT DR, CLARKSVILLE, TN, US 37040-5595</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$46.77</td>
                                    <td className="pb-1">$60.87</td>
                                    <td className="pb-1 pr-3"><span className="">$4.97</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="97" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue bg-light">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/23/2023</span> <span>5:28PM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-success">SHIPPED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Lumber Jack Pecan Blend BBQ Grilling Pellets - 20 lbs.</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1150051</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/781540950606" target="_blank">781540950606</a>
                                    </td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Mary Noneman</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 314-282-9402 ext. 16679</b></p>
                                                <p className="m-0">Buyer Email: <b>0g7v2nrfyld30y5@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Mary Noneman</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 314-282-9402 ext. 16679</b></p>
                                                <p className="m-0">Recipient Address: <b>8251 SILVERADO LN, RICHLAND, MI, US 49083-9798</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$22.72</td>
                                    <td className="pb-1">$30.88</td>
                                    <td className="pb-1 pr-3"><span className="">$3.53</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="96" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/23/2023</span> <span>3:51AM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-primary">ORDERED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Tough-1 Show Stopper Equitation Chaps Small Black</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">1285785</span>
                                    </td>
                                    <td className="pb-1"></td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Jessica Penne</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 619-854-2705 ext. 51858</b></p>
                                                <p className="m-0">Buyer Email: <b>frthdy2s6f00v3l@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Jessica Penne</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 619-854-2705 ext. 51858</b></p>
                                                <p className="m-0">Recipient Address: <b>869 69TH ST, SAN DIEGO, CA, US 92114-2925</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$68.26</td>
                                    <td className="pb-1">$91.89</td>
                                    <td className="pb-1 pr-3"><span className="">$9.85</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="95" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue bg-light">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/23/2023</span> <span>12:28AM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-success">SHIPPED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>48-22-8487 Milwaukee PACKOUT Large Mounting Wall Plate with 150 lbs. Capacity</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1442894</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/781538653993" target="_blank">781538653993</a>
                                    </td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Adam Burnette</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 213-442-1463 ext. 51840</b></p>
                                                <p className="m-0">Buyer Email: <b>ng79r0yp4yyjb51@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Adam Burnette</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 213-442-1463 ext. 51840</b></p>
                                                <p className="m-0">Recipient Address: <b>2331 CURRAN CT, LELAND, NC, US 28451-6413</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">2</td>
                                    <td className="pb-1">$93.08</td>
                                    <td className="pb-1">$135.74</td>
                                    <td className="pb-1 pr-3"><span className="">$22.30</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="94" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/22/2023</span> <span>5:49PM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-primary">ORDERED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Tough-1 Western Fringed Chaps, Brown Medium</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">1285806</span>
                                    </td>
                                    <td className="pb-1"></td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Cayla Crum</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 314-282-9402 ext. 22362</b></p>
                                                <p className="m-0">Buyer Email: <b>m0yvf79wbg0ky96@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Cayla Crum</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 314-282-9402 ext. 22362</b></p>
                                                <p className="m-0">Recipient Address: <b>11710 SARATOGA AVE, GUERNEVILLE, CA, US 95446-7604</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$66.79</td>
                                    <td className="pb-1">$89.94</td>
                                    <td className="pb-1 pr-3"><span className="">$9.66</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="93" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue bg-light">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/22/2023</span> <span>3:06PM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-success">SHIPPED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Lumber Jack Pecan Blend BBQ Grilling Pellets - 20 lbs.</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1150051</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/781573368257" target="_blank">781573368257</a>
                                    </td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Victor Ballard</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 763-225-9463 ext. 92505</b></p>
                                                <p className="m-0">Buyer Email: <b>z9g5fbnpbngsm8y@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Victor Ballard</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 763-225-9463 ext. 92505</b></p>
                                                <p className="m-0">Recipient Address: <b>663 HILL ST, ORFORDVILLE, WI, US 53576-9607</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$22.72</td>
                                    <td className="pb-1">$30.88</td>
                                    <td className="pb-1 pr-3"><span className="">$3.53</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="92" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/21/2023</span> <span>10:34AM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-success">SHIPPED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Little Tikes Princess Cozy Coupe, Princess Coupe Colorful, 33.5 Inch</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1425135</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/781462108808" target="_blank">781462108808</a>
                                    </td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>Emily Saxton</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 347-448-3190 ext. 80082</b></p>
                                                <p className="m-0">Buyer Email: <b>12qdpn451dqmsz3@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>Emily Saxton</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 347-448-3190 ext. 80082</b></p>
                                                <p className="m-0">Recipient Address: <b>6914 WINDHAM PKWY, PROSPECT, KY, US 40059-8863</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$71.68</td>
                                    <td className="pb-1">$94.85</td>
                                    <td className="pb-1 pr-3"><span className="">$8.94</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="91" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                                <tr className="bordered-blue bg-light">
                                    <td className="text-center pb-1 pl-3">
                                        <NavLink to="/order-details"><i className="icon-arrow-down15"></i></NavLink>
                                    </td>
                                    <td className="pb-1"><span className="d-block">07/20/2023</span> <span>11:37PM</span></td>
                                    <td>
                                        <NavLink to="/order-details"><span className="badge bg-success">SHIPPED</span></NavLink>
                                    </td>
                                    <td className="pb-1 text-nowrap product-details-wrap">
                                        <span className="d-block mb-1 overflow-hidden text-truncate" style={{maxWidth: '250px'}}>Lumber Jack Competition Blend Maple-Hickory-Cherry BBQ Grilling Pellets - 20 lbs.</span>
                                        <b>SUPPLIER:</b> <span className="text-info"></span> &nbsp;&nbsp;|&nbsp;&nbsp; <b>SKU:</b> <span className="text-info">JR1150047</span>
                                    </td>
                                    <td className="pb-1">
                                        <a href="https://www.aftership.com/track/781450455565" target="_blank">781450455565</a>
                                    </td>
                                    <td className="pb-1">
                                        <a href="#" className="list-icons-item dropdown-toggle text-info" data-toggle="dropdown" aria-expanded="true">View Details</a>
                                        <div className="dropdown-menu dropdown-menu-right bg-info" x-placement="bottom-end">
                                            <div className="p-2">
                                                <p className="m-0">Buyer Name: <b>James F Knipe</b></p>
                                                <p className="m-0">Buyer Phone: <b>+1 346-307-9643 ext. 38197</b></p>
                                                <p className="m-0">Buyer Email: <b>18r7rl8ktrk191h@marketplace.amazon.com</b></p>
                                                <hr className="mt-2 mb-2" />
                                                <p className="m-0">Recipient Name: <b>James F Knipe</b></p>
                                                <p className="m-0">Recipient Phone: <b>+1 346-307-9643 ext. 38197</b></p>
                                                <p className="m-0">Recipient Address: <b>5364 GRANDRIDGE RD, EL CAJON, CA, US 92020-7727</b></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pb-1">1</td>
                                    <td className="pb-1">$28.72</td>
                                    <td className="pb-1">$31.97</td>
                                    <td className="pb-1 pr-3"><span className="text-danger">$-1.55</span></td>
                                    <td className="pb-1">
                                        <i className="fa fa-check-circle fa-2x text-success"></i>
                                    </td>
                                    <td className="pb-1">
                                        <a className="set-auto-order" data-id="90" data-autorder="0"><i className="fa fa-3x fa-toggle-off"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <p className="mb-0">Total of 100 entries</p>
                <div className="pagination">
                    &nbsp;<span className="btn">1</span>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">2</button></a>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">3</button></a>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">4</button></a>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">5</button></a>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">6</button></a>&nbsp;
                    <a href="?page=order"><button className="btn btn-primary">Next</button></a>
                </div>
            </div>

            <Modal show={settings} onHide={() => setSettings(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Auto Order Settings
                        <button type="button" className='close' onClick={() => setSettings(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>Supplier:</label>
                                <input type="hidden" name="id[]" value="21" className="supplierid" />
                                <a href="https://www.aewholesale.com" target="_blank" className="form-control text-info" style={{textDecoration: 'underline'}}>AEWholesale (AEW)</a>
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-3">
                            <div className="form-group">
                                <label>Auto Order Status:</label>
                                <select name="auto_order_status[]" className="form-control">
                                    <option value="1">On</option>
                                    <option value="0">Off</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-3">
                            <div className="form-group">
                                <label>Last 4 CC:</label>
                                <input type="text" name="last4cc[]" value="1234" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>Username/Email:</label>
                                <input type="text" name="username[]" value="markanthonybillones273@gmail.com" className="form-control" />
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-6">
                            <div className="form-group">
                                <label>Password:</label>
                                <input type="password" name="password[]" value="skylar411" className="form-control" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setSettings(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default OrderManagement