import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

interface Props {}

const Feed = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Feed"
    }, []);

    const [alert, setAlert] = useState(true);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Inventory</a>
                            <a href="#" className="breadcrumb-item">Feed</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                {alert &&
                <div className="alert alert-info alert-styled-left alert-arrow-left alert-dismissible bg-white mb-4">
                    <button type="button" className="close" onClick={() => setAlert(false)}><span>×</span></button>
                    <h6 className="alert-heading font-weight-semibold mb-1">Load Inventory Feeds</h6>
                    Please select a Supplier and click "Load selected" button
                </div>
                }

                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="form-group">
                            <form method="GET">
                                <div className="input-group">
                                    <span className="input-group-prepend">
                                        <button type="submit" className="btn btn-primary">Load selected</button>
                                    </span>
                                    <select className="form-control m-bot15" name="selectedSupplier" id="vselWS">
                                        <option value="">- Select supplier -</option>
                                        <option value="aew">AEWholesale</option>
                                        <option value="bga">Bangalla</option>
                                        <option value="ckb">CKB Products</option>
                                        <option value="ctw">CTW Home Collection</option>
                                        <option value="osup">Other</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-6"></div>

                    <div className="col-xl-4 col-md-12 text-right"></div>
                </div>

                <div className="row mt-1">
                    <div className="col-md-12">
                        <div className="card">
                            <table className="table table-xs listing-table table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center"><input type="checkbox" className="control-input" id="check-all" /></th>
                                        <th>IMG</th>
                                        <th>PRODUCT</th>
                                        <th>PRICE</th>
                                        <th>SHIPPING</th>
                                        <th>QTY</th>
                                        <th>STATUS</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={8} className="text-center">Select supplier to add product into Queue.</td>
                                    </tr>
                                </tbody>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
   )
}

export default Feed