import React from 'react'

function Profile() {
    return (
        <div className="card br-10">
            <div className="card-header header-elements-inline">
                <h5 className="card-title">Profile Information</h5>
                <div className="header-elements"></div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Full Name</label>
                            <input type="text" name="fname" value="WHx Test" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Phone #</label>
                            <input type="text" name="phone" value="091234567" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Street Address</label>
                            <input type="text" name="address" value="test address" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email Address</label>
                            <input type="text" name="email" readOnly={true} value="dev@wholesalex.app" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>State</label>
                            <input type="text" name="state" value="State test" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>City</label>
                            <input type="text" name="city" value="Sacramento City" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>ZIP Code</label>
                            <input type="text" name="pcode" value="123456" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button type="button" id="profileUpdate" className="btn btn-primary">Save Changes</button>
                </div>
            </div>
        </div>
    )
}

export default Profile