import { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

interface Props {}

const FileTransfer = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | File Transfer"
    }, []);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">File Transfer</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row mb-3">
                    <div className="col-md-12 col-lg-8">
                        <div className="card h-100 br-10">
                            <div className="card-header header-elements-inline">
                                <h5 className="card-title">Upload CSV</h5>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="mb-3">To upload your product <code>csv</code> please download the template file, fill out the fields and save it. Click "Choose File" and then "Upload".</p>
                                        <p className="m-0">
                                            <span className="text-danger">NOTE:</span> Required fields
                                            <span className="badge badge-flat border-warning text-warning-600">PRODUCT NAME</span>
                                            <span className="badge badge-flat border-warning text-warning-600">PRICE</span>
                                            <span className="badge badge-flat border-warning text-warning-600">QUANTITY</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SKU</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SHIPPING</span>
                                        </p>

                                        <div className="mb-4 mt-2" style={{padding: '1em', background: '#f1f1f1', overflow: 'hidden'}}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4 className="mb-0">Feed - Item Bulk Upload</h4>
                                                    <p>File will be uploaded to "Feed" under "Inventory".</p>
                                                </div>
                                                <div className="col-6">
                                                    <input type="file" className="file-input" id="feedBulkUpload" accept=".csv" />
                                                </div>
                                                <div className="col-6 text-right">
                                                    <a href="../my-app/sample_csv/feed-bulk-upload-format.csv" className="text-nowrap">
                                                        <button type="button" className="btn btn-primary btn-labeled btn-labeled-left">
                                                            <b><i className="icon-file-download"></i></b>Download Template File
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="m-0">
                                            <span className="text-danger">NOTE:</span> Required fields
                                            <span className="badge badge-flat border-warning text-warning-600">PRODUCT NAME</span>
                                            <span className="badge badge-flat border-warning text-warning-600">PRICE</span>
                                            <span className="badge badge-flat border-warning text-warning-600">QUANTITY</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SKU</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SHIPPING</span>
                                        </p>

                                        <div className="mb-4 mt-2" style={{padding: '1em', background: '#f1f1f1', overflow: 'hidden'}}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4 className="mb-0">Queue - Item Bulk Upload</h4>
                                                    <p>File will be uploaded to "Queue" under "Inventory".</p>
                                                </div>
                                                <div className="col-6">
                                                    <input type="file" className="file-input" id="queueBulkUpload" accept=".csv" />
                                                </div>
                                                <div className="col-6 text-right">
                                                    <a href="../my-app/sample_csv/queue-bulk-upload-format.csv" className="text-nowrap">
                                                        <button type="button" className="btn btn-primary btn-labeled btn-labeled-left">
                                                            <b><i className="icon-file-download"></i></b>Download Template File
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="m-0">
                                            <span className="text-danger">NOTE:</span> Required fields
                                            <span className="badge badge-flat border-warning text-warning-600">PRODUCT NAME</span>
                                            <span className="badge badge-flat border-warning text-warning-600">PRICE</span>
                                            <span className="badge badge-flat border-warning text-warning-600">QUANTITY</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SKU</span>
                                            <span className="badge badge-flat border-warning text-warning-600">SHIPPING</span>
                                            <span className="badge badge-flat border-warning text-warning-600">ASIN</span>
                                        </p>

                                        <div className="mb-4 mt-2" style={{padding: '1em', background: '#f1f1f1', overflow: 'hidden'}}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4 className="mb-0">Catalog - Item Bulk Upload</h4>
                                                    <p>File will be uploaded to "Catalog" under "Inventory" and Amazon Seller Central.</p>
                                                    <p>1) If 3PL, must fill out <b>3PL HANDLING TIME</b>, <b>3PL SHIPPING</b> and <b>3PL ON HAND QUANTITY</b>.</p>
                                                </div>
                                                <div className="col-6">
                                                    <input type="file" className="file-input" id="catalogBulkUpload" accept=".csv" />
                                                </div>
                                                <div className="col-6 text-right">
                                                    <a href="../my-app/sample_csv/catalog-3pl-bulk-upload-format.csv" className="text-nowrap">
                                                        <button type="button" className="btn btn-primary btn-labeled btn-labeled-left">
                                                            <b><i className="icon-file-download"></i></b>Download Template File
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="m-0">
                                            <span className="text-danger">NOTE:</span> Required field
                                            <span className="badge badge-flat border-warning text-warning-600">SKU</span>
                                        </p>

                                        <div className="mt-2" style={{padding: '1em', background: '#f1f1f1', overflow: 'hidden'}}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4 className="mb-0">Catalog - Delete Close Listing Items</h4>
                                                    <p>Only Close Listing items will be deleted.</p>
                                                </div>
                                                <div className="col-6">
                                                    <input type="file" className="file-input" id="catalogDeleteBulkUpload" accept=".csv" />
                                                </div>
                                                <div className="col-6 text-right">
                                                    <a href="../my-app/sample_csv/catalog-delete-close-listing-format.csv" className="text-nowrap">
                                                        <button type="button" className="btn btn-primary btn-labeled btn-labeled-left">
                                                            <b><i className="icon-file-download"></i></b>Download Template File
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="card h-100 br-10">
                            <div className="card-body">
                                <div className="mb-3">
                                    <h5 className="card-title mb-0">Feed - Item Bulk Sample Upload</h5>
                                    <img src="assets/img/feed-bulk-upload-sample.png" style={{maxWidth: '100%', height: '8rem'}} className="img-fluid p-0 sample-upload w-100" id="feedSample" />
                                </div>
                                <div className="mb-3">
                                    <h5 className="card-title mb-0">Queue - Item Bulk Sample Upload</h5>
                                    <img src="assets/img/queue-bulk-upload-sample.png" style={{maxWidth: '100%', height: '8rem'}} className="img-fluid p-0 sample-upload w-100" id="queueSample" />
                                </div>
                                <div className="mb-3">
                                    <h5 className="card-title mb-0">Catalog - Item Bulk Sample Upload</h5>
                                    <img src="assets/img/catalog-3pl-bulk-upload-sample.png" style={{maxWidth: '100%', height: '8rem'}} className="img-fluid p-0 sample-upload w-100" id="catalogSample" />
                                </div>
                                <div className="mb-3">
                                    <h5 className="card-title mb-0">Catalog - Delete Close Listing Sample</h5>
                                    <img src="assets/img/catalog-delete-close-listing-sample.png" style={{maxWidth: '100%', height: '8rem'}} className="img-fluid p-0 sample-upload w-100" id="catalogDeleteSample" />
                                </div>
                                <button type="button" className="btn btn-primary mt-2 w-100" data-toggle="modal" data-target="#modal_default">Source Code List</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FileTransfer