import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faRefresh, faCheckCircle, faPencilSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

interface Props {}

const Queue = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Queue"
    }, []);

    const [addToCatalogMultiple, setAddToCatalogMultiple] = useState(false);
    const [removeFromQueueMultiple, setRemoveFromQueueMultiple] = useState(false);

    const [editProduct, setEditProduct] = useState(false);
    const [addTocatalog, setAddToCatalog] = useState(false);
    const [removeFromQueue, setRemoveFromQueue] = useState(false);

    const [activeStats, setActiveStats] = useState(0);
    const [activeIndex, setActiveIndex] = useState(-1);

    const queue_prods = [
        {
            id: 1,
            supplier: "ctw",
            product: "Pendant Lamp Canopy Kit",
            sku: 'AEW5950QYWA',
            asin: 'B00LENQYWA',
            total_cost: 0,
            qty: 1,
            amz_match: 2,
            status: 1
        }
    ]

    function mouseHandler(key:any) {
        setActiveStats(key.stat)
        setActiveIndex(key.key)
    }

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Inventory</a>
                            <a href="#" className="breadcrumb-item">Queue</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row">
                    <div className="col-xl-4 col-sm-6">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-prepend">
                                    <span className="btn btn-light">Supplier</span>
                                </span>
                                <select className="form-control m-bot15" name="supplier" id="queueSupplier">
                                    <option>- All -</option>
                                    <option value="aew">AEWholesale</option>
                                    <option value="bga">Bangalla</option>
                                    <option value="ckb">CKB Products</option>
                                    <option value="ctw">CTW Home Collection</option>
                                    <option value="osup">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="form-group">
                            <form>
                                <div className="input-group">
                                    <input type="text" name="search" id="searchVal" className="form-control" />
                                    <span className="input-group-btn">
                                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faSearch} /> Search</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-12">
                        <div className="form-group text-right">
                            <button className="btn btn-primary mr-1" onClick={() => setAddToCatalogMultiple(true)}>Add to Catalog</button>
                            <button className="btn btn-primary" onClick={() => setRemoveFromQueueMultiple(true)}>Remove from Queue</button>
                        </div>
                    </div>
                </div>

                <div className="row pr-0">
                    <div className="col-md-12">
                        <span className="float-right">
                            Legend: <FontAwesomeIcon icon={faRefresh} className='text-success' /> - For Amazon Validation | <FontAwesomeIcon icon={faCheckCircle} className='text-success' /> - Validated | <FontAwesomeIcon icon={faTimesCircle} className='text-danger' /> - Scratch Listing
                        </span>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-md-12 position-relative">
                        <div className='position-absolute' style={{marginTop: '3px'}}>
                            <div className="d-flex align-items-center mr-2">
                                <label className="m-0 mr-1">Show: </label>
                                <select className="form-control" style={{width: '70px'}}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                        
                        <Tabs
                            defaultActiveKey="single"
                            id="tabs"
                            className="mb-0"
                            style={{marginLeft: '115px'}}
                            >
                            <Tab eventKey="single" title={<strong>Single Listings</strong>}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body p-0 overflow-x-auto">
                                                <div style={{overflowY: 'auto',minHeight: '620px',maxHeight: '610px'}}>
                                                    <table className="table table-xs listing-table table-hover text-center">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center"><input type="checkbox" className="control-input" /></th>
                                                                <th>SUPPLIER</th>
                                                                <th style={{minWidth: '200px'}}>PRODUCT</th>
                                                                <th>SKU</th>
                                                                <th>ASIN</th>
                                                                <th className="text-nowrap">TOTAL COST</th>
                                                                <th>QTY</th>
                                                                <th className="text-nowrap">AMZ MATCH</th>
                                                                <th>STATUS</th>
                                                                <th style={{minWidth: '50px'}}>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {queue_prods.map((queue,key) => (
                                                                <tr key={key}>
                                                                    <td className='text-center'><input type="checkbox" className="control-input" id="checkfeedsitem" value="148" /></td>
                                                                    <td>CTW</td>
                                                                    <td>
                                                                        <a
                                                                            href=""
                                                                            target="_blank"
                                                                        >
                                                                            Pendant Lamp Canopy Kit
                                                                        </a>
                                                                    </td>
                                                                    <td>CTW0094BMT2</td>
                                                                    <td>B00MEPBMT2</td>
                                                                    <td className='position-relative'>
                                                                        <a href="#" className="list-icons-item dropdown-toggle text-info remove-dropdown-arrow" onMouseEnter={() => mouseHandler({key: 0, stat: 1})} onMouseOut={() => mouseHandler({key: 0, stat: 0})}>$7.09</a>
                                                                        <div className={'dropdown-menu dropdown-menu-right bg-info' + (activeIndex == key && activeStats == 1 ? ' show' : '')}>
                                                                            <div className="p-2">
                                                                                <p className="m-0">Listing Price: $6.5</p>
                                                                                <p className="m-0">Tax Rate: $0.59 (9%)</p>
                                                                                <p className="m-0">Supplier Shipping: $0</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>1</td>
                                                                    <td className="text-center">
                                                                        <a href="#" className="viewAmazonMatch" data-toggle="modal" data-target="#myModalViewMatchItem" data-id="148"><i className="fa fa-eye"></i></a>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <i className="fa fa-check-circle text-success"></i>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <DropdownButton className='remove-dropdown-arrow' title={<i className="icon-menu7"></i>}>
                                                                            <Dropdown.Item href="#" onClick={() => setEditProduct(true)}><FontAwesomeIcon icon={faPencilSquare} className='mr-2' /> Edit</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => setAddToCatalog(true)}><FontAwesomeIcon icon={faPlus} className='mr-2' /> Add to Catalog</Dropdown.Item>
                                                                            <Dropdown.Item href="#" onClick={() => setRemoveFromQueue(true)}><FontAwesomeIcon icon={faTrash} className='mr-2 text-danger' /> Remove from Queue</Dropdown.Item>
                                                                        </DropdownButton>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="multipack" title={<strong>Multipack Listings</strong>}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body p-0 overflow-x-auto">
                                                <div style={{overflowY: 'auto',minHeight: '620px',maxHeight: '610px'}}>
                                                    <table className="table table-xs listing-table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center"><input type="checkbox" className="control-input" /></th>
                                                                <th>SUPPLIER</th>
                                                                <th style={{minWidth: '200px'}}>PRODUCT</th>
                                                                <th>SKU</th>
                                                                <th>ASIN</th>
                                                                <th className="text-nowrap">TOTAL COST</th>
                                                                <th>QTY</th>
                                                                <th className="text-nowrap">AMZ MATCH</th>
                                                                <th>STATUS</th>
                                                                <th style={{minWidth: '50px'}}>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal show={addToCatalogMultiple} onHide={() => setAddToCatalogMultiple(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to add the items?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setAddToCatalogMultiple(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={removeFromQueueMultiple} onHide={() => setRemoveFromQueueMultiple(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to remove the items?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setRemoveFromQueueMultiple(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={editProduct} onHide={() => setEditProduct(false)}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        Edit Product
                        <button type="button" className='close' onClick={() => setEditProduct(false)}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12 mx-auto">
                            <div className="form-group">
                                <img alt="100%x180" data-src="holder.js/100%x180" style={{height: '100px', margin: 'auto', display: 'block'}} src="" data-holder-rendered="true" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-12">
                            <div className="form-group">
                                <label>TITLE</label>
                                <a
                                    href="https://ctwhomecollection.com/pc_combined_results.asp?search_prod=(searchlike~p.sku~920094|Or|searchlike~%20p.nm~920094|Or|searchlike~%20p.ds~920094|Or|searchlike~%20p.search_terms~920094)&amp;search_keyword=920094"
                                    className="form-control"
                                    target="_blank"
                                >
                                    Pendant Lamp Canopy Kit
                                </a>
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>BRAND</label>
                                <input id="vctbrand" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>CATEGORY</label>
                                <input id="vctcategory" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>SKU</label>
                                <input id="vctsku" className="form-control" value="CTW0094BMT2" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6">
                            <div className="form-group">
                                <label>ASIN</label>
                                <input id="vctasin" className="form-control" value="B00MEPBMT2" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-12">
                            <div className="form-group">
                                <label>Description:</label>
                                <textarea id="vctdesc" className="form-control" rows={4} >
                                    5'' diameter. We have had many requests for a canopy kit to use with our pendant lamps.  With our canopy kit, our pendant lamps can be professionally installed as a ceiling fixture, as shown. Includes hardware and strain relief.  Individually packaged for easy display in a bag with a header card.
                                </textarea>
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>UPC</label>
                                <input id="vctupc" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>MAP</label>
                                <input id="vctmap" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>MSRP</label>
                                <input id="vctmsrp" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4">
                            <div className="form-group">
                                <label>PRICE</label>
                                <input id="vctprice" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-4">
                            <div className="form-group">
                                <label>LISTING PRICE</label>
                                <input id="vctlistedprice" className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-4">
                            <div className="form-group">
                                <label>SHIPPING</label>
                                <input id="vctshipping" className="form-control" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setEditProduct(false)}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>

            <Modal show={addTocatalog} onHide={() => setAddToCatalog(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to add this item?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setAddToCatalog(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={removeFromQueue} onHide={() => setRemoveFromQueue(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to remove this item?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setRemoveFromQueue(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Queue