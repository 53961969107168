import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

interface Props {}

const Integration = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | Integration"
    }, []);

    const [ppt, setPPT] = useState(false);
    const [disconnectAmazon, setDisconnectAmazon] = useState(false);
    const showPPT = () => setPPT(true);
    const showDisconnectAmazon = () => setDisconnectAmazon(true);

    const closePPT = () => setPPT(false);
    const closeDisconnectAmazon = () => setDisconnectAmazon(false);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">Integration</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">   
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="card br-10">
                            <div className="card-body text-center">
                                <img className="p-4" src={require('./assets/img/amazon-spapi.png')} style={{width: '16.5rem'}} />
                                <p className="mb-1">Authorize wholesalex.app to connect to your Amazon account.</p>
                                <h4 className="mb-0">Merchant/Seller ID:</h4>
                                <h4><strong className="text-info">A3G13L023KY64</strong></h4>
                                <button className="btn btn-danger" onClick={showDisconnectAmazon}>Disconnect</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="card br-10">
                            <div className="card-body text-center">
                                <img className="p-3" src={require('./assets/img/ppai.png')} style={{width: '14.5rem'}} />
                                <p className="mb-1">Sync your listing cost price using your generated  API. </p>
                                <h4 className="mb-0">API Key:</h4>
                                <h4><strong className="text-info">[your_api_key]</strong></h4>
                                <a href="#" className="btn bg-primary-400" onClick={showPPT}>Integrate</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={disconnectAmazon} onHide={closeDisconnectAmazon} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to disconnect?</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={closeDisconnectAmazon}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={ppt} onHide={closePPT}>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        PrincePointAI
                        <button type="button" className='close' onClick={closePPT}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            <div className="form-group">
                                <label>Access Key</label>
                                <input id="accesskey" className="form-control" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={closePPT}><i className="icon-cross"></i> Close</button>
                    <button type="button" className="btn btn-primary"><i className="icon-paperplane"></i> Save</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Integration