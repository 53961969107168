import React from 'react'

function ChangePassword() {
    return (
        <div className="card br-10">
            <div className="card-header header-elements-inline">
                <h5 className="card-title">Change Password</h5>
                <div className="header-elements"></div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" name="fname" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    <button type="button" id="profileUpdate" className="btn btn-primary">Save Changes</button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword