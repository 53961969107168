import { useState, useEffect } from 'react';
import axios from "axios";
import { NavLink, useOutletContext, useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();

    var api = useOutletContext();

    const [invalid, isInvalid] = useState(false);

    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });

    const handleChange = (event:any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = (event:any) => {
        event.preventDefault();
        
        axios.post(api+'/server.php?action=login', inputs)
        .then((response) => {
            if(response.data.status === 'success'){
                localStorage.setItem('userData', JSON.stringify(response.data.data));

                // window.location.href = '/dashboard';
                navigate('/dashboard');
            }else{
                isInvalid(true);
            }
        });
    }

    return (
        <div className="content d-flex justify-content-center align-items-center">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <div style={{padding: '20px'}}><img src="assets/img/wholesaleX72.png" className="img-fluid" alt='WholeSaleX Logo'/></div>
                            <h5 className="mb-0">Login to your account</h5>
                            <span className="d-block text-muted">Your credentials</span>
                        </div>
                        { invalid &&
                            <div id="invalidCredentials" className='text-center text-danger'>Invalid username or password</div>   
                        }
                        <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="text" className="form-control" id="username" name="username" placeholder="Username" value={inputs.username || ""} onChange={handleChange}/>
                            <div className="form-control-feedback">
                                <i className="icon-user text-muted"></i>
                            </div>
                        </div>

                        <div className="form-group form-group-feedback form-group-feedback-left">
                            <input type="password" className="form-control" id="password" name="password" placeholder="Password" value={inputs.password || ""} onChange={handleChange}/>
                            <div className="form-control-feedback">
                                <i className="icon-lock2 text-muted"></i>
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <label className="form-check-label">
                                    <input type="checkbox" name="remember" id="showpass" className="form-input-styled mr-1"/>
                                    Show Password
                                </label>
                            </div>
                        </div>
                        
                        <div className="text-center">
                            <NavLink to="/forgot-password" className="ml-auto">Forgot password?</NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <NavLink to="/login-va" className="ml-auto">Login as VA?</NavLink>
                        </div><br/>

                        <div className="form-group">
                            <button type="submit" name="submit" className="btn btn-primary btn-block">Sign in <i className="icon-circle-right2 ml-2"></i></button>
                        </div>

                        <div className="form-group text-center text-muted content-divider">
                            <span className="px-2">Don't have an account?</span>
                        </div>
                    
                        <div className="form-group">
                            <a href="https://wholesalex.app/lp/#pricing" className="btn btn-light btn-block">Sign up</a>
                        </div>

                        <span className="form-text text-center text-muted">By continuing, you're confirming that you've read our <a href="https://wholesalex.app/termsandconditions.html" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://wholesalex.app/privacyandpolicy.html" target="_blank" rel="noreferrer">Privacy Policy</a></span>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login