import {useState} from 'react'
import Modal from 'react-bootstrap/Modal'

function BillingManagement() {
    const [cancelSubscription, setCancelSubscription] = useState(false)
    const [planStatus, setPlanStatus] = useState({action: ''})
    const [planStatusModal, setPlanStatusModal] = useState(false)

    function clickHandler(key:any){
        setPlanStatus({action: key})
        setPlanStatusModal(true)
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-2">Subscription Plan</h5>
                <div className="d-flex justify-content-between mb-2">
                    <a href="#" onClick={() => setCancelSubscription(true)} className="btn btn-danger plan-status mr-2" data-planstatus="Cancel"><i className="icon-blocked"></i> Cancel</a>
                    <a href="#" onClick={() => clickHandler('Downgrade')} className="btn btn-primary plan-status" data-planstatus="Downgrade" data-toggle="modal" data-target="#ticketModal"><i className="fa fa-download"></i> Downgrade</a>
                    <a href="#" onClick={() => clickHandler('Upgrade')} className="btn btn-success plan-status ml-2" data-planstatus="Upgrade" data-toggle="modal" data-target="#ticketModal"><i className="fa fa-upload"></i> Upgrade</a>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <h6 className="d-flex justify-content-between mb-1">
                                <span>Active Plan:</span>
                                <span><strong className="text-danger">$129</strong> (growth)</span>
                                <input type="hidden" id="active-plan" value="growth" />
                            </h6>
                        </div>
                        <div className="col-lg-12">
                            <h6 className="d-flex justify-content-between mb-1">
                                <span>Plan Date:</span>
                                <strong>N/A</strong>
                            </h6>
                        </div>
                        <div className="col-lg-12">
                            <h6 className="d-flex justify-content-between mb-1">
                                <span>Status:</span>
                                <strong className="text-danger">NOT FOUND</strong>
                            </h6>
                        </div>
                        <div className="col-lg-12">
                            <h6 className="d-flex justify-content-between mb-1">
                                <span>Method:</span>
                                <strong>Stripe</strong>
                            </h6>
                        </div>
                        <div className="col-lg-12">
                            <h6 className="d-flex justify-content-between mb-0">
                                <span>Card Email:</span>
                                <strong>N/A</strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header pt-2 pb-2">
                    <h5 className="m-0">Subscription Plan History</h5>
                </div>
                <div className="card-body" style={{overflowX: 'auto'}}>
                    <table className="table table-xs listing-table table-hover">
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Date</th>
                                <th>Listings</th>
                                <th>Amount</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-info">Growth</td>
                                <td className="text-nowrap">2023-04-01 04:20:21</td>
                                <td>3000</td>
                                <td>$129</td>
                                <td className="text-info">Signup</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal show={cancelSubscription} onHide={() => setCancelSubscription(false)} centered>
                <Modal.Header>
                    <h2 className="col-12 modal-title text-center">
                        <i className='icon-notification2 icon-5x text-info'></i>
                    </h2>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4 className='mb-4'>Are you sure you want to Cancel your subscription plan? you will not be able to login afterwards.</h4>
                    <div className='d-inline'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setCancelSubscription(false)}><i className="icon-cross"></i> No</button>
                        <button type="button" className="btn btn-danger"><i className="icon-check"></i> Yes</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={planStatusModal} onHide={() => setPlanStatusModal(false)}>
                <Modal.Header>
                    <h4 className="modal-title" id="myModalLabel">ADD TICKET:</h4>
                    <button type="button" className="close" aria-label="Close" onClick={() => setPlanStatusModal(false)}><span aria-hidden="true">×</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Priority Level:</label><br/>
                                <select id="supportlevelticket" className="form-control">
                                    <option value="">-- Select Priority -- </option>
                                    <option value="1">Critical</option>
                                    <option value="2">Important</option>
                                    <option value="3">Normal</option>
                                    <option value="4">Low</option>
                                </select>
                            </div>
                        </div>
                
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Subject:</label><br/> 
                                <input type="text" id="ticketsubject" className="form-control" value={planStatus.action} readOnly />
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea id="descriptionticket" className="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => setPlanStatusModal(false)}><i className="icon-cross"></i> Cancel</button>
                        <button type="button" className="btn btn-primary"><i className="icon-check"></i> Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BillingManagement