import { useState } from "react"
import { NavLink } from 'react-router-dom'

interface Props {}

const Sidebar = (props: Props) => {
    const currentpage = window.location.pathname
    const inventorypage = currentpage.includes('inventory') ? true : false
    const supportpage = currentpage.includes('support') ? true : false

    const [isInventory, setIsInventory] = useState(inventorypage);
    const [isSupport, setIsSupport] = useState(supportpage);

    const inventorySubmenu = () => {
        setIsInventory(!isInventory)
    }

    const supportSubmenu = () => {
        setIsSupport(!isSupport)
    }
    
    return (
        <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md bg-blue-700">
            <div className="sidebar-mobile-toggler text-center">
                <a href="#" className="sidebar-mobile-main-toggle">
                    <i className="icon-arrow-left8"></i>
                </a>
                Navigation
                <a href="#" className="sidebar-mobile-expand">
                    <i className="icon-screen-full"></i>
                    <i className="icon-screen-normal"></i>
                </a>
            </div>

            <div className="sidebar-content">
                <div className="sidebar-user">
                    <div className="card-body">
                        <div className="media">
                            <div className="mr-3">
                                <div className="btn bg-success-400 rounded-round btn-icon btn-sm mr-2">
                                    <span className="letter-icon">WT</span>
                                </div>
                            </div>

                            <div className="media-body">
                                <div className="media-title font-weight-semibold">WHx Test</div>
                                <div className="font-size-xs">
                                    <i className="icon-pin font-size-sm"></i> Sacramento City
                                </div>
                            </div>

                            <div className="ml-3 align-self-center">
                                <NavLink to="/settings" className="text-white"><i className="icon-pencil4"></i></NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-sidebar-mobile">
                    <ul className="nav nav-sidebar" data-nav-type="accordion">
                        <li className="nav-item-header">
                            <div className="text-uppercase font-size-xs line-height-xs">Main</div> <i className="icon-menu" title="Main"></i>
                        </li>
                        <li className="nav-item">
                            <NavLink to="dashboard" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="icon-home4"></i>
                                <span>
                                    Dashboard
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="setup-supplier" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="icon-repo-forked"></i>
                                <span>
                                    Setup Supplier
                                </span>
                            </NavLink>
                        </li>
                        <li className={isInventory ? 'nav-item nav-item-submenu nav-item-open' : 'nav-item nav-item-submenu'}>
                            <a href="#" className="nav-link" onClick={inventorySubmenu}><i className="icon-copy"></i> <span>Inventory</span></a>

                            <ul className={isInventory ? 'nav nav-group-sub d-block' : 'nav nav-group-sub'} data-submenu-title="Layouts">
                                <li className="nav-item"><NavLink to="inventory/feed" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-list-numbered"></i>Feed</NavLink></li>
                                <li className="nav-item"><NavLink to="inventory/queue" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-list-numbered"></i>Queue</NavLink></li>
                                <li className="nav-item"><NavLink to="inventory/catalog" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-list-numbered"></i>Catalog</NavLink></li>
                            </ul>
                        </li>
                    
                        <li className="nav-item">
                            <NavLink to="file-transfer" className="nav-link">
                                <i className="icon-file-text2"></i>
                                <span>
                                    File Transfer
                                </span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink to="order-management" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="icon-gift"></i>
                                <span>
                                    Order Management
                                </span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink to="integration" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                                <i className="icon-link2"></i>
                                <span>
                                    Integration
                                </span>
                            </NavLink>
                        </li>
                        
                        <li  className={isSupport ? 'nav-item nav-item-submenu nav-item-open' : 'nav-item nav-item-submenu'}>
                            <a href="#" className="nav-link" onClick={supportSubmenu}><i className="icon-lifebuoy"></i> <span>Support</span></a>

                            <ul  className={isSupport ? 'nav nav-group-sub d-block' : 'nav nav-group-sub'} data-submenu-title="Layouts">
                                <li className="nav-item"><NavLink to="support/tickets" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-ticket"></i>My Tickets</NavLink></li>
                                <li className="nav-item"><NavLink to="support/faq" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-bubble-notification"></i>FAQ</NavLink></li>
                                <li className="nav-item"><NavLink to="support/knowledgebase" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-book3"></i>Knowledgebase</NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item"><NavLink to="forum" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-bubbles4"></i>Forum</NavLink></li>
                        <li className="nav-item"><NavLink to="high-value-item" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-gift"></i>High Value Item</NavLink></li>
                        <li className="nav-item"><NavLink to="sitemap" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}><i className="icon-tree6"></i>Sitemap</NavLink></li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Sidebar