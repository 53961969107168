import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {}

const HighValueItem = (props: Props) => {
    useEffect(() => {
        document.title = "WholesaleX | High Value Item";
    }, []);

    return (
        <>
            <div className="page-header page-header-light">
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline"> 
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <NavLink to="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</NavLink>
                            <a href="#" className="breadcrumb-item">High Value Item</a>
                            <span className="breadcrumb-item active"></span>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="content">
                <div className="card br-10">
                    <div className="card-header header-elements-inline">
                        <h5 className="card-title">Item Lists</h5>
                        <div className="header-elements">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#viewMoreModal" ><i className="icon-folder-plus2 mr-2"></i> VIEW MORE</button>
                        </div>
                    </div>
                    
                    <div className="table-responsive">
                        <table className="table table-xs listing-table table-hover">
                            <thead>
                                <tr>
                                    <th style={{width: 80}}>IMG</th>
                                    <th style={{minWidth: 200}}>DETAILS FROM AMAZON</th>
                                    <th style={{minWidth: 200}}>DETAILS FROM SUPPLIER</th>
                                    <th style={{minWidth: 140}}>LOWEST PRICE WITH SHIPPING</th>
                                    <th>PURCHASING COST</th>
                                    <th>PROFIT/ROI</th>
                                    <th>SALES RANK</th>
                                    <th># OF SELLERS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="https://m.media-amazon.com/images/W/MEDIAX_849526-T1/images/I/51We6CthKUL._AC_SX679_.jpg" style={{width: 80}} placeholder="Item Image"/>
                                    </td>
                                    <td>
                                        <p><a href="https://www.amazon.com/L-Theanine-Caffeine-Nootropic-Supplement-Certified/dp/B07MQWJYJ3" target="_blank">Sports Research L-Theanine Supplement with Caffeine &amp; Coconut MCT Oil - Focused Energy, Alertness &amp; Relaxation Without Drowsiness - 200mg L Theanine, 100mg Organic Caffeine - 60 Liquid Softgels</a></p>
                                        <p className="description">
                                            Product Dimensions &rlm; : &lrm; 2.13 x 2.13 x 4 inches; 3.53 ounces
                                            Item model number &rlm; : &lrm; 023249012269
                                            Date First Available &rlm; : &lrm; January 14, 2019
                                            Manufacturer &rlm; : &lrm; Sports Research
                                            ASIN &rlm; : &lrm; B07MQWJYJ3
                                        </p>
                                    </td>
                                    <td>
                                        <p>Sports Research L-Theanine Supplement with Caffeine &amp; Coconut MCT Oil</p>
                                        <p className="description">
                                            Product Dimensions &rlm; : &lrm; 2.13 x 2.13 x 4 inches; 3.53 ounces
                                            Item model number &rlm; : &lrm; 023249012269
                                            Date First Available &rlm; : &lrm; January 14, 2019
                                            Manufacturer &rlm; : &lrm; Sports Research
                                        </p>
                                    </td>
                                    <td>$15</td>
                                    <td>$13.56</td>
                                    <td>
                                        <strong className="text-danger">$-1.44</strong>
                                        <span className="badge bg-danger">-10.62%</span>
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <p className="mb-0">Total: 3</p>
                                        <p className="mb-0">FBA:0</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="https://m.media-amazon.com/images/I/81s9PtbwRTL._AC_SX679_PIbundle-3,TopRight,0,0_SH20_.jpg" style={{width: 80}} placeholder="Item Image"/>
                                    </td>
                                    <td>
                                        <p><a href="https://www.amazon.com/Caffeine-L-Theanine-Clarity-Nootropic-Extended/dp/B078X2S1WS" target="_blank">SmarterVitamins (3-Pack) 200mg Caffeine Pills with 100mg L-Theanine for Energy, Focus and Clarity + Coconut MCT Oil, Pre Workout, Nootropic Brain Booster, Extended Release Capsule</a></p>
                                        <p className="description">
                                            Package Dimensions &rlm; : &lrm; 6.3 x 5.43 x 2.05 inches; 0.9 ounces
                                            Date First Available &rlm; : &lrm; January 9, 2018
                                            Manufacturer &rlm; : &lrm; SmarterVitamins
                                            ASIN &rlm; : &lrm; B078X2S1WS
                                        </p>
                                    </td>
                                    <td>
                                        <p>SmarterVitamins</p>
                                        <p className="description">
                                            Package Dimensions &rlm; : &lrm; 6.3 x 5.43 x 2.05 inches; 0.9 ounces
                                            Date First Available &rlm; : &lrm; January 9, 2018
                                            Manufacturer &rlm; : &lrm; SmarterVitamins
                                        </p>
                                    </td>
                                    <td>$12</td>
                                    <td>$63.47</td>
                                    <td>
                                        <strong className="text-success">$51.47</strong>
                                        <span className="badge bg-success">81.09%</span>
                                    </td>
                                    <td>12</td>
                                    <td>
                                        <p className="mb-0">Total: 12</p>
                                        <p className="mb-0">FBA:12</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HighValueItem