import React from 'react'

interface Props {}

const Footer = (props: Props) => {
  return (
    <div className="navbar navbar-expand-lg navbar-light">
        <div className="text-center d-lg-none w-100">
            <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                <i className="icon-unfold mr-2"></i>
                Footer
            </button>
        </div>

        <div className="navbar-collapse collapse" id="navbar-footer">
            <span className="navbar-text">
                &copy; 2023 WholesaleX
            </span>
        </div>
    </div>
  )
}

export default Footer