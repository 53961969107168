import { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

interface Props {}

const Navbar = (props: Props) => {
    const [viewDisplay, setViewDisplay] = useState(true)
    const [updateTrigger, setUpdateTrigger] = useState(false)
    const [settingsTrigger, setSettingsTrigger] = useState(false)

    function viewHandler(){
        if(viewDisplay){
            document.body.classList.add('sidebar-xs');
        }else{
            document.body.classList.remove('sidebar-xs');
        }

        setViewDisplay(!viewDisplay)
    }

    const newRef = useRef<any>();
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick, { capture: true });
        return () => {
        document.removeEventListener("mousedown", handleOutsideClick, { capture: true });
        };
    });

    const handleOutsideClick = (e:any) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            setUpdateTrigger(false);
        }
    };

    const settingRef = useRef<any>();
    useEffect(() => {
        document.addEventListener("mousedown", settingsOutsideClick, { capture: true });
        return () => {
        document.removeEventListener("mousedown", settingsOutsideClick, { capture: true });
        };
    });

    const settingsOutsideClick = (e:any) => {
        if (settingRef.current && !settingRef.current.contains(e.target)) {
            setSettingsTrigger(false);
        }
    };

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("userData");
        navigate('/login');
    };

    return (
        <div className="navbar navbar-expand-md navbar-dark bg-blue-700" style={{borderBottom: 'none'}}>
            <div className="navbar-brand" id="wholesalex-navbar-brand">
                {
                    viewDisplay ? <img src="assets/img/wholesaleX72white.png" alt="" className="wholesalex-logo" /> : <h1 className="wholesalex-short-title" >WS</h1>
                }
            </div>
            
            <div className="d-md-none">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                    <i className="icon-tree5"></i>
                </button>
                <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
                    <i className="icon-paragraph-justify3"></i>
                </button>
            </div>

            <div className="collapse navbar-collapse" id="navbar-mobile" style={{height: '65px'}}>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="#" className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block" onClick={() => viewHandler()}>
                            <i className="icon-paragraph-justify3"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown" ref={newRef}>
                        <a href="#" className="navbar-nav-link dropdown-toggle caret-0" onClick={() => setUpdateTrigger(!updateTrigger)}>
                            <i className="icon-git-compare" style={{color: '#0c5483'}}></i>
                            <span className="d-md-none ml-2">Wholesalex updates</span>
                            <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">1</span>
                        </a>

                        <div className={'dropdown-menu dropdown-content wmin-md-350' + (updateTrigger ? ' show' : '')}>
                            <div className="dropdown-content-header">
                                <span className="font-weight-semibold">Wholesalex updates</span>
                                <a href="#" className="text-default"><i className="icon-sync"></i></a>
                            </div>

                            <div className="dropdown-content-body dropdown-scrollable">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="media-body">
                                            Application release date will be posted here so please try to check it every now and then. Thank you.
                                            <div className="text-muted font-size-sm">10/10/2023</div>
                                        </div>
                                    </li>

                                    <li className="media">
                                        <div className="media-body">
                                            File Transfer Catalog bulk upload for 3PL and non-3PL items
                                            <div className="text-muted font-size-sm">10/24/2023</div>
                                        </div>
                                    </li>

                                    <li className="media">
                                        <div className="media-body">
                                            File Transfer - Bulk Delete CSV for items under Close Listing
                                            <div className="text-muted font-size-sm">10/24/2023</div>
                                        </div>
                                    </li>

                                    <li className="media">
                                        <div className="media-body">
                                            File Transfer - Update Inventory under Catalog upload file for non-supported suppliers
                                            <div className="text-muted font-size-sm">10/25/2023</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="dropdown-content-footer bg-light">
                                <a href="#" className="text-grey mr-auto">All updates</a>
                                <div>
                                    <a href="#" className="text-grey" data-popup="tooltip" title="" data-original-title="Mark all as read"><i className="icon-radio-unchecked"></i></a>
                                    <a href="#" className="text-grey ml-2" data-popup="tooltip" title="" data-original-title="Bug tracker"><i className="icon-bug2"></i></a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <span className="badge ml-md-3 mr-md-auto"> </span>
                <NavLink to="/support/tickets" className="navbar-nav-link custom-btn">
                    <i className="icon-ticket mr-0 "></i>
                    <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0 ticket-notification-counter">0</span>
                </NavLink>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown dropdown-user" ref={settingRef}>
                        <a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" style={{color: '#0c5483'}} onClick={() => setSettingsTrigger(!settingsTrigger)}>
                            <div className="btn bg-success-400 rounded-round btn-icon btn-sm mr-2">
                                <span className="letter-icon">WT</span>
                            </div>
                            <span>WHx Test</span>
                        </a> 

                        <div className={'dropdown-menu dropdown-menu-right' + (settingsTrigger ? ' show' : '')}>
                            <NavLink to="/settings" className="dropdown-item custom-btn"><i className="icon-user-plus"></i> My profile</NavLink>
                            <NavLink to="/va-management" className="dropdown-item custom-btn"><i className="icon-cog5"></i> VA Management</NavLink>
                            <button className="dropdown-item custom-btn" onClickCapture={logout}><i className="icon-switch2"></i> Logout</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar