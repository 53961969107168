import { createBrowserRouter } from "react-router-dom"
import App from "./App"
import Dashboard from "./components/Dashboard"
import SetupSupplier from "./components/SetupSupplier"
import Feed from "./components/inventory/Feed"
import Queue from "./components/inventory/Queue"
import Catalog from "./components/inventory/Catalog"
import FileTransfer from "./components/FileTransfer"
import OrderManagement from "./components/OrderManagement"
import OrderDetails from "./components/OrderDetails"
import Integration from "./components/Integration"
import Tickets from "./components/support/Tickets"
import FAQ from "./components/support/FAQ"
import Knowledgebase from "./components/support/Knowledgebase"
import Forum from "./components/Forum"
import Sitemap from "./components/Sitemap"
import HighValueItem from "./components/HighValueItem"
import Settings from "./components/Settings"
import VAManagement from "./components/settings/VAManagement"
import Theme from "./components/unauthenticated/Theme"
import Login from "./components/unauthenticated/Login"
import LoginVA from "./components/unauthenticated/LoginVA"
import ForgotPassword from "./components/unauthenticated/ForgotPassword"

export const router = createBrowserRouter([
    {
        element: <Theme />,
        children: [
            { path: "", element: <Login /> },
            { path: "login", element: <Login /> },
            { path: "login-va", element: <LoginVA /> },
            { path: "forgot-password", element: <ForgotPassword /> },
        ]
    },
    {
        element: <App />,
        children: [
            { path: "dashboard", element: <Dashboard /> },
            { path: "setup-supplier", element: <SetupSupplier /> },
            { path: "inventory/feed", element: <Feed /> },
            { path: "inventory/queue", element: <Queue /> },
            { path: "inventory/catalog", element: <Catalog /> },
            { path: "file-transfer", element: <FileTransfer /> },
            { path: "order-management", element: <OrderManagement /> },
            { path: "order-details", element: <OrderDetails /> },
            { path: "integration", element: <Integration /> },
            { path: "support/tickets", element: <Tickets /> },
            { path: "support/faq", element: <FAQ /> },
            { path: "support/knowledgebase", element: <Knowledgebase /> },
            { path: "forum", element: <Forum /> },
            { path: "high-value-item", element: <HighValueItem /> },
            { path: "sitemap", element: <Sitemap /> },
            { path: "settings", element: <Settings /> },
            { path: "va-management", element: <VAManagement /> }
        ]
    }
])